import React, { useState } from "react";
import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import { SetStateAction } from "react";
import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import styled from "styled-components";

const DatePickerContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;

  .MuiFormControl-root {
    .MuiFormLabel-root,
    .MuiInputBase-input {
      display: none;
    }

    .MuiButtonBase-root {
      margin: 0;
      color: #fff;
    }

    .MuiInputBase-root {
      padding: 0;
      background: transparent;

      &:hover {
        &::before {
          border-bottom: none;
        }
      }

      &::before {
        border: 0;
      }

      &::after {
        border: 0;
      }

      .MuiInputAdornment-root {
        height: unset;
        max-height: unset;
        margin: 0;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0;
        padding: 0;
      }
    }
  }
`;

const DateSelector: React.FC<{
  date: DateTime | null;
  onChange: (newValue: SetStateAction<DateTime | null>) => void;
  minDate?: DateTime | null;
  maxDate?: DateTime | null;
}> = ({ date, onChange, minDate, maxDate }) => {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  return (
    <DatePickerContainer>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          value={date || null}
          onChange={(newValue) => {
            if (DateTime.isDateTime(newValue)) {
              onChange(newValue);
            }
          }}
          onClose={() => setShowDatePicker(false)}
          open={showDatePicker}
          slots={{
            openPickerIcon: (params) => (
              <CalendarTodayOutlined
                {...params}
                fontSize="small"
                onClick={() => setShowDatePicker(true)}
              />
            ),
          }}
          maxDate={maxDate || undefined}
          minDate={minDate || undefined}
        />
      </LocalizationProvider>
      <time>{date ? date.toFormat("yyyy/MM/dd") : ""}</time>
    </DatePickerContainer>
  );
};

export default DateSelector;
