import {
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components";
import OSIntelItem from "../OSIntListItem/OSIntListItem";
import DateSelector from "../../../../components/DateSelector/DateSelector";
import useOSInt, { OSItelType } from "./useOSInt";

const DropDownButton = styled(Button)`
  color: #fff;
  text-transform: none;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 16px 16px 16px;
`;

const OSIntelTitle = styled.h2`
  font-size: 14px;
  font-weight: 700;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: rgb(27, 29, 45);
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: #fff;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const OSIntFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OSIntHeader = styled.div`
  display: flex;
  align-items: center;
`;

const NoResultsMessage = styled.div`
  padding-top: 35px;
  color: gray;
`;

const OSIntel: React.FC = () => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleClick,
    anchorEl,
    handleClose,
    handleMenuItemClick,
    handleClickSortBy,
    selectedSortBByValue,
    sortByEl,
    handleCloseSortBy,
    handleSortByMenuItemClick,
    handleSelectOsiIntItem,
    webcamDataIsLoading,
    currentOSIntelData,
    selectOsIntItemId,
    selectedKey,
  } = useOSInt();

  return (
    <Sidebar>
      <OSIntHeader>
        <OSIntelTitle>Open Source Intelligence</OSIntelTitle>
        <DateSelector
          date={startDate}
          onChange={(newValue) => {
            setStartDate(newValue);
          }}
          maxDate={endDate}
        />
        <DateSelector
          date={endDate}
          onChange={(newValue) => {
            setEndDate(newValue);
          }}
          minDate={startDate}
        />
      </OSIntHeader>
      <Divider sx={{ width: "100%", backgroundColor: "#393C56" }} />
      <OSIntFilters>
        <div>
          <DropDownButton onClick={handleClick}>
            Show: {selectedKey} <KeyboardArrowDownIcon fontSize="small" />
          </DropDownButton>
          <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {Object.entries(OSItelType).map(([key, value]) => (
              <StyledMenuItem
                key={key}
                onClick={() => handleMenuItemClick(value)}
              >
                {key}
              </StyledMenuItem>
            ))}
          </StyledMenu>
        </div>
        <div>
          <DropDownButton onClick={handleClickSortBy}>
            Sort by:{" "}
            {selectedSortBByValue === "asc"
              ? "Newest to oldest"
              : "Oldest to newest"}
            <KeyboardArrowDownIcon fontSize="small" />
          </DropDownButton>
          <StyledMenu
            anchorEl={sortByEl}
            open={Boolean(sortByEl)}
            onClose={handleCloseSortBy}
          >
            <StyledMenuItem
              key={"asc"}
              onClick={() => handleSortByMenuItemClick("asc")}
            >
              Newest to oldest
            </StyledMenuItem>
            <StyledMenuItem
              key={"desc"}
              onClick={() => handleSortByMenuItemClick("desc")}
            >
              Oldest to newest
            </StyledMenuItem>
          </StyledMenu>
        </div>
      </OSIntFilters>
      {webcamDataIsLoading ? (
        <CircularProgress
          sx={{
            color: "#ffffff",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "40px",
            width: "100%",
          }}
          size={40}
        />
      ) : (
        <div>
          {currentOSIntelData.map((item) => {
            return (
              <OSIntelItem
                osIntelItem={item}
                key={item.id}
                isSelected={selectOsIntItemId === item.id}
                handleSelectOsiIntItem={handleSelectOsiIntItem}
              />
            );
          })}
        </div>
      )}
      {!webcamDataIsLoading && currentOSIntelData.length === 0 ? (
        <NoResultsMessage>
          We are sorry we were unable to find data for the selected date range.
        </NoResultsMessage>
      ) : null}
    </Sidebar>
  );
};

export default OSIntel;
