import React from "react";
import useEmailMfa from "./useEmailMfa";
import styles from "./MFA.module.scss";
import { Box, Typography, TextField, Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { LoadingButton } from "@mui/lab";

interface EmailMfaProps {
  user: any;
  handleFlip: () => void;
  isFlipped: boolean;
}

const EmailMfa = ({ user, handleFlip, isFlipped }: EmailMfaProps) => {
  const {
    emailAuth,
    sendEmailCode,
    verifyEmailOtp,
    error,
    mfaCode,
    handleChangeCode,
    handleKeyPress,
    inputRefs,
    loading,
  } = useEmailMfa(user, isFlipped);

  const isCodeComplete = () => mfaCode.every((digit) => digit !== "");

  const getRemainingDigits = () => 6 - mfaCode.filter(Boolean).length;

  const getSubmitButtonText = () => {
    return isCodeComplete() ? "Submit" : `${getRemainingDigits()} digits left`;
  };

  return (
    <div style={{ minWidth: "100%" }}>
      <form
        className={styles.detailInputWrapper}
        onSubmit={(e) => {
          e.preventDefault();
          emailAuth && verifyEmailOtp();
        }}
      >
        <Box className={styles.inputWrapperMain}>
          <Box className={styles.inputWrapper}>
            <div
              style={{
                height: "1em",
              }}
            />
            <Typography variant="h6" className={styles.twoStepVerButton}>
              Verify By Email
            </Typography>
            {error && (
              <Typography
                sx={{
                  border: "1px solid #FA5849",
                  color: "white",
                  backgroundColor: "#FA584980",
                  width: "100%",
                  padding: "1em",
                  borderRadius: "5px",
                }}
              >
                <ErrorOutlineIcon
                  sx={{
                    marginBottom: "-0.2em",
                    marginRight: "0.2em",
                  }}
                />
                The code is invalid.
              </Typography>
            )}
            <div
              style={{
                height: "0.5em",
              }}
            />
            <Typography className={styles.textMfa}>
              {emailAuth
                ? `Enter the verification code sent to your email.`
                : `Click on “Send Code” to send verification code to your email.`}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                width: "100%",
                justifyContent: "center",
              }}
            >
              {emailAuth && (
                <>
                  <Box
                    className={styles.textFieldWrapper}
                    sx={{
                      margin: "0px 0px 13px 0px",
                      alignSelf: "center",
                    }}
                  >
                    {mfaCode.map((digit, index) => (
                      <Box
                        className={
                          error ? styles.errorTextField : styles.textField
                        }
                        key={index}
                        style={{ marginRight: index === 2 ? "10px" : "0" }}
                      >
                        <TextField
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            style: {
                              textAlign: "center",
                              height: "40px",
                              color: "white",
                              backgroundColor: "#080913",
                            },
                          }}
                          autoComplete="off"
                          id={`code-${index}`}
                          type="text"
                          name={`code-${index}`}
                          value={digit}
                          variant="standard"
                          onChange={(e) => handleChangeCode(e, index)}
                          inputRef={(e) => (inputRefs.current[index] = e)}
                          onKeyDown={(e) => handleKeyPress(e, index)}
                        />
                      </Box>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      color: "white",
                      alignSelf: "flex-end",
                      marginRight: "5em",
                    }}
                  >
                    <Typography>{"Didn’t receive it?"}</Typography>
                    <Typography
                      sx={{
                        color: "#00a3e3",
                        paddingLeft: "0.6em",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={sendEmailCode}
                    >
                      Resend
                    </Typography>
                  </Box>
                </>
              )}
            </div>
            <div
              style={{
                height: "2em",
              }}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={handleFlip}
                disabled={loading}
                sx={{
                  color: "#FFFFFF",
                }}
              >
                {" "}
                {`<- Back to 2-step verification`}
              </Button>
              {!emailAuth ? (
                <LoadingButton
                  variant="contained"
                  className={styles.buttonWrap}
                  sx={{
                    "&.Mui-disabled": {
                      color: "black",
                      backgroundColor: "#00a3e3",
                    },
                  }}
                  onClick={sendEmailCode}
                  size={"small"}
                  loading={loading}
                >
                  Send Code
                </LoadingButton>
              ) : (
                <LoadingButton
                  variant="contained"
                  className={styles.buttonWrap}
                  disabled={!emailAuth || !isCodeComplete()}
                  onClick={verifyEmailOtp}
                  loading={loading}
                >
                  {getSubmitButtonText()}
                </LoadingButton>
              )}
            </div>
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default EmailMfa;
