import styled from "styled-components";
import { Tab, Box, Typography } from "@mui/material";


export const AlertsTabPanelDiv = styled.div`
  overflow-y: auto;
  height: 95%;
`

export const AlertsMainTabsBox = styled(Box)`
width: 100%;
 height: 100%;
`

export const BoxContainer = styled(Box)`
border-bottom: 1; 
border-color: 'divider';
color: "white;
`

export const TabComponent = styled(Tab)`
  &.MuiTab-root {
    color: #FFFFFF;
  } 
  &.Mui-selected {
    color: #FFFFFF;
    opacity: 1;
    border-block-color: #FFFFFF;
  }
`

export const AlertsCardDiv = styled.div`
    display : flex;
    flex-direction : column;
    height : 100%;
    padding : 1em 0.5em 1em 1em;
`

export const AlertsCardBox = styled(Box)`
height: 100%;
`

export const SpinnerContainerBox = styled(Box)`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
`

export const PlaceholderTypography = styled(Typography)`
color: 'white';
text-align: 'center';
margin-top: '20px';
`