import { Dispatch, FC, SetStateAction } from "react";
import { Box, Checkbox, FormControlLabel, Menu, MenuItem } from "@mui/material";
import styles from "./PathSettingMenu.module.scss";
import { PathData } from "../../../MainMap";
import ColorPicker from "../../../../../components/ColorPicker/ColorPicker";
import usePathSettingsMenu from "./usePathSettingsMenu";

interface PathSettingMenuInterface {
  handleClose: () => void;
  anchorEl: null | HTMLElement;
  open: boolean;
  id: string;
  shipPaths: {
    [key: string]: {
      path: PathData[];
      color: string;
      showPath: boolean;
      showAllpoints: boolean;
      showShip?: boolean;
    };
  };
  setShipPaths: Dispatch<
    SetStateAction<{
      [key: string]: {
        path: PathData[];
        color: string;
      };
    }>
  >;
}

const PathSettingMenu: FC<PathSettingMenuInterface> = ({
  handleClose,
  anchorEl,
  open,
  id,
  shipPaths,
  setShipPaths,
}) => {
  const { pathColor, setPathColor } = usePathSettingsMenu({
    id,
    shipPaths,
    setShipPaths,
  });

  return (
    <Menu
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      anchorEl={anchorEl}
      className={styles.pathSettingMenuWrap}
      classes={{
        root: styles.pathSettingMenuRoot,
        paper: styles.pathSettingMenuPaper,
        list: styles.pathSettingMenuList,
      }}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 218, left: 530 }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
      <Box className={styles.pathSettingMenuItemWrap}>
        <MenuItem className={styles.pathSettingMenuItem}>
          <FormControlLabel
            className={styles.pathSettingFormControl}
            control={
              <Checkbox
                size="medium"
                className={styles.pathSettingCheckbox}
                classes={{
                  checked: styles.itemChecked,
                }}
              />
            }
            classes={{
              label: styles.pathSettingLabel,
            }}
            label="Show Ship"
            checked={shipPaths[id]?.showShip ? true : false}
            onChange={(e) => {
              setShipPaths((prev) => ({
                ...prev,
                [id]: {
                  ...prev[id],
                  showShip: !shipPaths[id]?.showShip,
                },
              }));
            }}
          />
        </MenuItem>
        <MenuItem className={styles.pathSettingMenuItem}>
          <FormControlLabel
            className={styles.pathSettingFormControl}
            control={
              <Checkbox
                size="medium"
                className={styles.pathSettingCheckbox}
                classes={{
                  checked: styles.itemChecked,
                }}
              />
            }
            classes={{
              label: styles.pathSettingLabel,
            }}
            checked={shipPaths[id]?.showAllpoints ? true : false}
            onChange={(e) => {
              setShipPaths((prev) => ({
                ...prev,
                [id]: {
                  ...prev[id],
                  showAllpoints: !shipPaths[id]?.showAllpoints,
                },
              }));
            }}
            label="Show All Pointers"
          />
        </MenuItem>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <span className={styles.pathSettingLabel}>Circle and path color</span>
      </Box>
      <ColorPicker
        color={pathColor}
        setColor={(color) => {
          setPathColor(color);
        }}
      />
    </Menu>
  );
};

export default PathSettingMenu;
