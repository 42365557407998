import { useState } from "react";

const useOSIntListItem = (): {
  showMoreIsExpanded: boolean;
  handleShowMoreClick: () => void;
} => {
  const [showMoreIsExpanded, setShowMoreIsExpanded] = useState<boolean>(false);

  const handleShowMoreClick = (): void => {
    setShowMoreIsExpanded((prev) => !prev);
  };

  return { showMoreIsExpanded, handleShowMoreClick };
};

export default useOSIntListItem;
