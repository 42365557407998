import axios from "axios";
import { OSItelType } from "./useOSInt";
import { REACT_APP_BASE_URL } from "../../../../utils/Constants";

export interface OSInteligence {
  id: string;
  title: string;
  source_id: string;
  osint_type: string;
  timestamp: string;
  latitude: number;
  longitude: number;
  description: string;
  lastUpdated: string;
  incident_date: string | null;
  issued_date: string | null;
}

export const getOSIntData = async ({
  startDate,
  endDate,
  type,
}: {
  startDate: string;
  endDate: string;
  type: OSItelType;
}): Promise<OSInteligence[]> => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get(
      `${REACT_APP_BASE_URL}/v1/osint/${type}?start=${startDate}&end=${endDate}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    );
    return res.data;
  } catch (error: any) {
    throw new Error("Error fetching Open Source Intelligence:", error);
  }
};

export const getAllOSIntData = async ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): Promise<OSInteligence[]> => {
  try {
    const osIntTypes = [OSItelType.UKMTO, OSItelType["ICC-CCS"]];

    const osIntDataPromises = osIntTypes.map((type) =>
      getOSIntData({ startDate, endDate, type }),
    );

    const allData = await Promise.allSettled(osIntDataPromises);

    const successfulData: OSInteligence[] = [];
    for (const result of allData) {
      if (result.status === "fulfilled") {
        successfulData.push(...result.value);
      } else {
        console.warn("Promise failed:", result.reason);
      }
    }

    return successfulData;
  } catch (error) {
    throw new Error("Error fetching Open Source Intelligence: " + error);
  }
};
