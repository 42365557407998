import useCurrentMapState from "../../../../context/useCurrentMapState";
import SynMaxIcon from "../../../Shared/SynMaxIcon";
import { Button, Collapse } from "@mui/material";
import styled from "styled-components";
import OSIntelItemDescriptionOpen from "./OSIntelItemDescriptionOpen";
import { OSInteligence } from "../OSIntel/model";
import { OSItelType } from "../OSIntel/useOSInt";
import useOSIntListItem from "./useOSIntListItem";
import { calculateTimeSince } from "../../../../utils/utils";
import { SHOW_MORE_MILLISECONDS } from "../../../../utils/Constants";

const OSInteligenceListItem = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: #24263c;
  width: 100%;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 16px;
  border: ${({ isSelected }) => (isSelected ? "1px solid white" : "none")};
`;

const OSIntelItemHeader = styled.div`
  display: flex;
  align-items: center;
`;

const OSItemTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
`;

const OSItemTime = styled.div`
  margin-left: auto;
  font-size: 12px;
  font-weight: 400;
`;

const Description = styled.div`
  margin-top: 12px;
  padding-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GoToMapButton = styled(Button)`
  color: white;
  text-transform: none;
  text-decoration: underline;
  font-size: 12px;
  margin-top: 2px;
  padding-left: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OSIntelItem: React.FC<{
  osIntelItem: OSInteligence;
  isSelected: boolean;
  handleSelectOsiIntItem: (id: string) => void;
}> = ({ osIntelItem, isSelected, handleSelectOsiIntItem }) => {
  const { handleSelectedOsIntItem } = useCurrentMapState();

  const { showMoreIsExpanded, handleShowMoreClick } = useOSIntListItem();

  return (
    <OSInteligenceListItem isSelected={isSelected}>
      <OSIntelItemHeader>
        <SynMaxIcon
          icon={osIntelItem.source_id === OSItelType.UKMTO ? "pin" : "ICCCCS"}
          size={20}
        />
        <OSItemTitle>
          {osIntelItem.source_id === OSItelType.UKMTO ? "UKMTO" : "ICC-CCS"}
        </OSItemTitle>
        <OSItemTime>{calculateTimeSince(osIntelItem.timestamp)}</OSItemTime>
      </OSIntelItemHeader>
      <Collapse
        in={showMoreIsExpanded}
        timeout={SHOW_MORE_MILLISECONDS}
        unmountOnExit
      >
        <OSIntelItemDescriptionOpen osIntelItem={osIntelItem} />
      </Collapse>
      {!showMoreIsExpanded && (
        <Description>
          {osIntelItem.description || "No description available"}
        </Description>
      )}
      <ButtonContainer>
        <GoToMapButton
          variant="text"
          type="button"
          onClick={handleShowMoreClick}
        >
          {showMoreIsExpanded ? "Show Less" : "Show More"}
        </GoToMapButton>
        {osIntelItem.latitude && osIntelItem.longitude ? (
          <GoToMapButton
            variant="text"
            type="button"
            onClick={() => {
              handleSelectedOsIntItem(osIntelItem);
              handleSelectOsiIntItem(osIntelItem.id);
            }}
          >
            Go to Map
          </GoToMapButton>
        ) : null}
      </ButtonContainer>
    </OSInteligenceListItem>
  );
};

export default OSIntelItem;
