import { useState, useEffect, useRef } from "react";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { User } from "firebase/auth";
import { useSnackbar } from "../../context/SnackbarContext";

const useEmailMfa = (user: User | null, isFlipped: boolean) => {
  const [mfaCode, setMfaCode] = useState(Array(6).fill(""));

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  // State to record if email code was send
  const [emailAuth, setEmailAuth] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  // navigation function
  const navigate = useNavigate();

  // snackbar context
  const { setNewSnackBar } = useSnackbar();

  useEffect(() => {
    if (mfaCode.every((element) => element === "") && error) {
      setError(false);
    }
  }, [mfaCode, error]);

  // Function to send email code
  const sendEmailCode = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await api.post("/mfa/send-email-code", {
        email: user?.email,
        uid: user?.uid,
      });
      setNewSnackBar({ message: response.data, severity: "success" });
      setEmailAuth(true);
    } catch {
      setNewSnackBar({
        message: "Failed to send email code",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCode = (e: any, index: number) => {
    setError(false);
    const value = e.target.value;
    if (/^\d{0,1}$/.test(value)) {
      const updatedCode = [...mfaCode];
      updatedCode[index] = value;
      setMfaCode(updatedCode);

      if (value !== "" && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyPress = (e: any, index: number) => {
    if (e.key === "Backspace" && index > 0 && mfaCode[index] === "") {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const verifyEmailOtp = async (): Promise<void> => {
    if (user) {
      try {
        setLoading(true);
        const response = await api.post("/mfa/verify-email-code", {
          uid: user?.uid,
          token: mfaCode.join(""),
          email: user?.email,
        });

        if (response.data.valid) {
          localStorage.setItem("isMFAVerified", "true");
          navigate("/theia");
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
        console.error("Error logging in:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const allDigitsFilled = mfaCode.every((digit) => digit !== "");
    if (allDigitsFilled && emailAuth) {
      verifyEmailOtp();
    }
  }, [mfaCode]);

  useEffect(() => {
    if (!isFlipped) {
      setTimeout(() => {
        setEmailAuth(false);
        error && setError(false);
        setMfaCode(Array(6).fill(""));
      }, 1000);
    }
  }, [isFlipped]);

  // Return necessary functions and states
  return {
    emailAuth,
    sendEmailCode,
    verifyEmailOtp,
    error,
    mfaCode,
    setMfaCode,
    handleChangeCode,
    handleKeyPress,
    inputRefs,
    loading,
  };
};

export default useEmailMfa;
