import { DateTime } from "luxon";
import styled from "styled-components";
import { OSInteligence } from "../OSIntel/model";

const ExpandedTimeStamp = styled.div`
  font-size: 10px;
  color: #fff;
  opacity: 0.5;
  margin-top: 12px;
`;

const Timestamp = styled.div`
  padding-top: 2px;
`;

const DescriptionExpanded = styled.div`
  padding-top: 2px;
`;

const DescriptionHeader = styled.div`
  font-size: 10px;
  color: #fff;
  opacity: 0.5;
  margin-top: 12px;
`;

const formatDateForOsInt = (date: string): string => {
  return DateTime.fromISO(date).toFormat("MMM dd, yyyy 'at' HH:mm");
};

const OSIntelItemDescriptionOpen: React.FC<{
  osIntelItem: OSInteligence;
}> = ({ osIntelItem }) => {
  return (
    <>
      {osIntelItem.incident_date !== null && (
        <>
          <ExpandedTimeStamp>Incident Date & Time</ExpandedTimeStamp>
          <Timestamp>{formatDateForOsInt(osIntelItem.incident_date)}</Timestamp>
        </>
      )}
      {osIntelItem.issued_date !== null && (
        <>
          <ExpandedTimeStamp>Issued Date & Time</ExpandedTimeStamp>
          <Timestamp>{formatDateForOsInt(osIntelItem.issued_date)}</Timestamp>
        </>
      )}
      <DescriptionHeader>Report</DescriptionHeader>
      <DescriptionExpanded>
        {osIntelItem.description || "No description available"}
      </DescriptionExpanded>
    </>
  );
};

export default OSIntelItemDescriptionOpen;
