import ValidatedPasswordInput from "../ValidatedPasswordInput/ValidatedPasswordInput";
import PasswordInput from "../PasswordInput/PasswordInput";
import ActionButton from "../ActionButon";
import styled from "styled-components";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import useUpdateForgottenPassword from "./useForgottenPassword";

const ChangePasswordTitle = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
`;

const UpdatePasswordContainer = styled.div`
  background-color: #111326;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 36px 72px;
  width: 460px;
`;

const UpdateForgottenPassword: React.FC = () => {
  const {
    setNewPassword,
    newPassword,
    confirmPassword,
    setPasswordIsValid,
    passwordError,
    passwordIsValid,
    hanldeSetConfirmPassword,
    handleUserUpdatePassword,
  } = useUpdateForgottenPassword();

  return (
    <UpdatePasswordContainer>
      <ChangePasswordTitle>Update Password</ChangePasswordTitle>
      {passwordError !== null && (
        <ErrorMessage
          message={passwordError}
          style={{ marginBottom: "36px" }}
        />
      )}
      <ValidatedPasswordInput
        label="New Password"
        currentPassword={newPassword}
        setCurrentPassword={setNewPassword}
        setPasswordIsValid={setPasswordIsValid}
        error={passwordError !== null}
        inputStyle={{ marginTop: "9px" }}
        placeholder="New Password"
      />
      <PasswordInput
        label="Confirm Password"
        currentPassword={confirmPassword}
        setCurrentPassword={hanldeSetConfirmPassword}
        error={passwordError !== null}
        inputStyle={{ marginTop: "9px" }}
        placeholder="Confirm Password"
      />
      <ActionButton
        label="Update"
        variantType="contained"
        width="100%"
        type="submit"
        disabled={
          newPassword.length === 0 ||
          newPassword !== confirmPassword ||
          passwordError !== null ||
          !passwordIsValid
        }
        onClick={handleUserUpdatePassword}
        style={{ marginTop: "24px" }}
      />
    </UpdatePasswordContainer>
  );
};

export default UpdateForgottenPassword;
