import { useCallback, useEffect, useState } from "react";
import { OSInteligence, getAllOSIntData, getOSIntData } from "./model";
import useCurrentMapState from "../../../../context/useCurrentMapState";
import { DateTime } from "luxon";
import { sortData } from "../../../../utils/utils";

export enum OSItelType {
  UKMTO = "ukmto",
  "ICC-CCS" = "icc_ccs",
  All = "All",
}

const useOSInt = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortByEl, setSortBgAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState<OSItelType>(
    OSItelType.All,
  );
  const [selectedSortBByValue, setSelectedSortByValue] = useState<
    "asc" | "desc"
  >("asc");
  const [currentOSIntelData, setCurrentOsIntelData] = useState<OSInteligence[]>(
    [],
  );
  const [selectOsIntItemId, setSelectedOsintItemId] = useState<string | null>(
    null,
  );

  const handleSelectOsiIntItem = (id: string) => {
    setSelectedOsintItemId(id);
  };

  const [webcamDataIsLoading, setWebcamDataIsLoading] = useState(true);

  const { handleSelectedOsIntItem } = useCurrentMapState();

  const handleCloseSortBy = () => {
    setSortBgAnchorEl(null);
  };

  const handleClickSortBy = (event: React.MouseEvent<HTMLElement>) => {
    setSortBgAnchorEl(event.currentTarget);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (key: OSItelType) => {
    setSelectedValue(key);
    handleClose();
  };

  const handleSortByMenuItemClick = (key: "desc" | "asc") => {
    setSelectedSortByValue(key);
    sortOsIntData(currentOSIntelData, key);
    handleCloseSortBy();
  };

  const [startDate, setStartDate] = useState<DateTime | null>(
    DateTime.now().minus({ months: 3 }),
  );

  const [endDate, setEndDate] = useState<DateTime | null>(DateTime.now());

  const selectedKey = Object.entries(OSItelType).find(
    ([key, value]) => value === selectedValue,
  )?.[0];

  const sortOsIntData = useCallback(
    (data: OSInteligence[], key: "desc" | "asc") => {
      const comparator = (a: OSInteligence, b: OSInteligence) => {
        const aDate = DateTime.fromISO(a.timestamp, { zone: "utc" });
        const bDate = DateTime.fromISO(b.timestamp, { zone: "utc" });

        return key === "asc"
          ? bDate.toMillis() - aDate.toMillis()
          : aDate.toMillis() - bDate.toMillis();
      };

      const sortedData = sortData(data, comparator);
      setCurrentOsIntelData(sortedData);
    },
    [],
  );

  useEffect(() => {
    const fetchOSIntData = async () => {
      const formattedStartDate = startDate?.toISODate();
      const formattedEndDate = endDate?.toISODate();

      if (formattedStartDate && formattedEndDate) {
        if (selectedValue === OSItelType.All) {
          const osIntData = await getAllOSIntData({
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          });
          sortOsIntData(osIntData, selectedSortBByValue);
        } else {
          const osIntData = await getOSIntData({
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            type: selectedValue,
          });
          sortOsIntData(osIntData, selectedSortBByValue);
        }

        setWebcamDataIsLoading(false);
      }
    };
    fetchOSIntData();
  }, [selectedValue, startDate, endDate, getOSIntData, sortOsIntData]);

  useEffect(() => {
    return () => {
      // Remove icon from map when component unmounts
      handleSelectedOsIntItem(null);
    };
  }, []);

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleClick,
    anchorEl,
    handleClose,
    handleMenuItemClick,
    handleClickSortBy,
    selectedSortBByValue,
    sortByEl,
    handleCloseSortBy,
    handleSortByMenuItemClick,
    handleSelectOsiIntItem,
    webcamDataIsLoading,
    currentOSIntelData,
    selectOsIntItemId,
    selectedKey,
  };
};

export default useOSInt;
