import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PathData } from "../../../MainMap";

interface UsePathSettingsMenuProps {
  id: string;
  shipPaths: {
    [key: string]: {
      path: PathData[];
      color: string;
      showPath: boolean;
      showAllpoints: boolean;
      showShip?: boolean;
    };
  };
  setShipPaths: Dispatch<
    SetStateAction<{
      [key: string]: {
        path: PathData[];
        color: string;
      };
    }>
  >;
}

const usePathSettingsMenu = ({
  id,
  shipPaths,
  setShipPaths,
}: UsePathSettingsMenuProps) => {
  const [pathColor, setPathColor] = useState<string>(
    shipPaths?.[id]?.color || "#fff",
  );

  useEffect(() => {
    setShipPaths((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        color: pathColor,
      },
    }));
  }, [pathColor, id, setShipPaths]);

  return { pathColor, setPathColor };
};

export default usePathSettingsMenu;
