import { DateTime, Duration } from "luxon";

export const getToolTipDeckGl = ({ layer, object }: any) => {
  return object && layer && layer?.id === "droppedPins" && object.properties
    ? {
        html: `<div>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>Point: ${object.properties?.title}</p>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>lat:   ${object.properties?.lat}</p>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>lng:   ${object.properties?.lng}</p>
       </div> `,
        style: {
          fontSize: "0.8em",
          padding: "15px",
          backgroundColor: "#24263c",
          minWidth: "100px",
          gap: "5px",
        },
      }
    : {
        style: {
          display: "none",
        },
      };
};

export const defaultImplementation = () => {
  throw new Error("Function not implemented");
};

export const calculateTimeSince = (timeToCalculateFrom: string): string => {
  const currentUUTCTime = DateTime.utc();
  const formatedTime = DateTime.fromISO(timeToCalculateFrom, { zone: "utc" });

  if (!formatedTime.isValid) {
    throw new Error("Invalid timestamp format");
  }

  const diff = currentUUTCTime
    .diff(formatedTime, ["days", "hours", "minutes"])
    .toObject();

  const days = diff.days ? Math.floor(diff.days) : 0;
  const hours = diff.hours ? Math.floor(diff.hours) : 0;
  const minutes = diff.minutes ? Math.floor(diff.minutes) : 0;
  const seconds = diff.seconds ? Math.floor(diff.seconds) : 0;

  if (days >= 1) {
    return `${days}d`;
  } else if (hours >= 1) {
    return `${hours}h`;
  } else if (minutes >= 1) {
    return `${minutes}m`;
  } else {
    return `${seconds}s`;
  }
};

export const getTodaysDate = (): string => {
  return DateTime.now().toFormat("yyyy-MM-dd");
};

export const containsNumber = (string: string): boolean => {
  return /\d/.test(string);
};

export const containsUppercase = (string: string): boolean => {
  for (let i = 0; i < string.length; i++) {
    if (
      string[i] === string[i].toUpperCase() &&
      string[i] !== string[i].toLowerCase()
    ) {
      return true;
    }
  }
  return false;
};

export const containsSpecailCharacters = (password: string): boolean => {
  // Check for specail characters using NIST Special Publication 800-63B (Digital Identity Guidelines)
  const specialCharacterRegex = /.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*/;
  return specialCharacterRegex.test(password);
};

export const formatSeconds = (seconds: number): string => {
  return Duration.fromObject({ seconds }).toFormat("m:ss");
};

export const GLOBAL_AOI =[
      [
        [-150.8203125, 73.77577986189996],
        [186.67968750000003, 73.77577986189996],
        [186.67968750000003, -59.80063426102869],
        [-150.8203125, -59.80063426102869],
        [-150.8203125, 73.77577986189996],
      ],
    ]
export const sortData = <T>(
  data: T[],
  comparator: (a: T, b: T) => number,
): T[] => {
  return [...data].sort(comparator);
};
