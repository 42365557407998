// Import necessary libraries
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import React, { useRef, useMemo } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import useCurrentMapState from '../../../../context/useCurrentMapState';
import { useCreateAlerts } from '../../../../context/CreateAlertContext';
import { useViewAlerts } from '../../../../context/ViewAlertsContext';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { triggersList } from './AlertsBuilder/Modals/AlertTriggers/ShipTriggers';
import styles from './alertsWrapper.module.scss';
import { api } from "../../../../services/api";
import { useSnackbar } from '../../../../context/SnackbarContext';
import {
  StepperAlertInfo,
  DividerComponent,
  SpaceDiv,
  TriggeredAlertsHeader,
  StyledStepConnector,
  TriggeredAlertTypography,
  StepIconComponent,
  StepLabelComponent,
  LatLongBox,
  LongitudeBox,
  ViewMapButton,
  HeaderTypography,
  TriggeredAlertButton,
  StyledCard,
  CardContentBox,
  StyledTypographyButton
} from './AlertCardStyled';
import { DateTime } from 'luxon';

const formatDateUTCLong = (date: any) => {
  return DateTime.fromJSDate(new Date(date)) // Convert JS Date to Luxon DateTime
    .toUTC() // Ensure the date is in UTC
    .toFormat('MMM d, yyyy, h:mm:ss a ZZZZ')
};

const formatDateUTCShort = (date: any) => {
  return DateTime.fromJSDate(new Date(date)) // Convert JS Date to Luxon DateTime
    .toUTC() // Ensure the date is in UTC
    .toFormat('M/d/yy, h:mm a')
};


function TriggeredAlertsStepper({ alert, triggered, expanded, handleShipIconClick, acknowledgedCount }: any) {
  const {
    mapRef,

  } = useCurrentMapState();
  const { setNewSnackBar } = useSnackbar();

  const [preacknowledge, setPreacknowledge] = React.useState<number[]>([])

  const displayTriggerLabel = useMemo(() => {
    return triggersList.filter(trigger => trigger.enum === alert.alert_name)[0]?.label || alert.alert_name
  }, [alert.alert_name])


  const acknowledgeAlert = async (step: any) => {
    if (mapRef) {
      const f = {
        object: {
          properties: {
            type: "alert",
            isActiveShip: true,
            synmax_ship_id: step.synmax_ship_id,
            timestamp: step.timestamp,
            object_id: step.object_id,
          },
        }
      }
      handleShipIconClick("alertLayer", f);
      mapRef.current.flyTo({ center: [step.longitude, step.latitude] })
      setPreacknowledge((pre) => [...pre, step.event_id])
      try {
        await api.post(`/alerts/acknowledge`, { event_id: step.event_id })
      } catch (error) {
        setNewSnackBar({
          message: `${error}`,
          severity: "error",
        });
        console.log(error)
      }
    }
  }

  return (
    <Box
      sx={{ maxWidth: 400 }}
    >
      {
        expanded &&
        <>
          <StepperAlertInfo
          >
            <Box >
              <Typography
                className={styles.itemtitle}
              >
                Ship
              </Typography>
              <Typography
                className={styles.itemvalue}
              >
                {alert.name}
              </Typography>
            </Box>
            <Box
              className={styles.item}
            >
              <Typography
                className={styles.itemtitle}
              >Trigger
              </Typography>
              <Typography
                className={styles.itemvalue}
              >
                {
                  displayTriggerLabel
                }
              </Typography>
            </Box>
            <Box
              className={styles.item}
            >
              <Typography
                className={styles.itemtitle}
              >Area
              </Typography>
              <Typography
                className={styles.itemvalue}
              >
                World-wide
              </Typography>
            </Box>
            <Box className={styles.item} >
              <Typography
                className={styles.itemtitle}
              >
                End Date
              </Typography>
              <Typography
                className={styles.itemvalue}
              >{
                  formatDateUTCShort(alert.end_date)
                }
              </Typography>
            </Box>
            <Box
              className={styles.item}
            >
              <Typography
                className={styles.itemtitle}
              > Start Date
              </Typography>
              <Typography
                className={styles.itemvalue}
              >
                {
                  formatDateUTCShort(alert.start_date)
                }
              </Typography>
            </Box>
            <Box className={styles.item} >
              <Typography
                className={styles.itemtitle}
              > Frequency
              </Typography>
              <Typography
                className={styles.itemvalue}
              >Real Time
              </Typography>
            </Box>
            <Box
              className={styles.item} >
              <Typography
                className={styles.itemtitle}
              > Email For Notification </Typography>
              <Typography
                className={styles.itemvalue}
              >
                {alert.destination}
              </Typography>
              <SpaceDiv />
            </Box>
          </StepperAlertInfo>
          <DividerComponent />
          <TriggeredAlertsHeader
          >
            {(triggered.length - acknowledgedCount - preacknowledge.length) > 0 &&
              <Typography
                className={styles.alertsCountCircle}
              >
                {triggered.length - acknowledgedCount - preacknowledge.length + ""}
              </Typography>
            }
            <TriggeredAlertTypography
            >
              Triggered Alert(s):
            </TriggeredAlertTypography>
          </TriggeredAlertsHeader>
          <Box>
            {!triggered.length &&
              <Typography>
                No alerts have been triggered.
              </Typography>
            }
          </Box>
          <SpaceDiv />
          <Stepper orientation="vertical"
            connector={
              <StyledStepConnector
              />
            }
          >
            {triggered && triggered.map((step: any, index: any) => (
              <Step active expanded key={index}>
                <StepLabelComponent
                  StepIconComponent={() =>
                    <StepIconComponent style={{
                      backgroundColor: step.acknowledged_alert || [...preacknowledge].includes(step.event_id) ? "#FFFFFF" : "#FA5849",
                    }}
                    />}
                  sx={{
                    "& .MuiStepLabel-label": {
                      color: step.acknowledged_alert || [...preacknowledge].includes(step.event_id) ? "#FFFFFF !important" : "#FA5849 !important"
                    }
                  }}
                >
                  {
                    formatDateUTCLong(step.timestamp)
                  }
                </StepLabelComponent>
                <StepContent
                  sx={{
                    borderLeft: (triggered.length === index + 1) ? null : "2px dashed #FFFFFF"
                  }}
                >
                  <LatLongBox>
                    <LongitudeBox
                    >
                      <Typography
                        className={styles.itemtitle}
                      >Longitude</Typography>
                      <Typography>{step.longitude}</Typography>
                    </LongitudeBox>
                    <Box>
                      <Typography
                        className={styles.itemtitle}
                      >Latitude</Typography>
                      <Typography>{step.latitude}</Typography>
                    </Box>
                  </LatLongBox>
                  <Box>
                    <ViewMapButton
                      variant={
                        step.acknowledged_alert || [...preacknowledge].includes(step.event_id) ? "outlined" : "contained"
                      }

                      onClick={() => acknowledgeAlert(step)}
                    >
                      View Event on Map
                    </ViewMapButton>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </>
      }
    </Box>
  );
}

const AlertCard = ({ alert, name, triggered, handleShipIconClick, acknowledgedCount }: any) => {

  const { handleTabChange } = useViewAlerts();
  const { updateAlert, setIsUpdate } = useCreateAlerts();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const targetRef = useRef<null | HTMLDivElement>(null);

  // This is just a simple card component that displays the name, type, ship, and createdOn date of an alert.
  // This is WIP and can be modified as needed.

  const editAlert = (e: any, alert: any) => {
    handleTabChange(e, 1);
    updateAlert(alert);
    setIsUpdate(true);

  }

  const closeAlert = () => {
    if (targetRef.current && expanded) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setExpanded((prev) => !prev);
  }

  return (
    <div
      ref={targetRef}
    >
      <StyledCard>
        <CardContent>
          <CardContentBox>
            <HeaderTypography variant="h5">
              {triggered.length - acknowledgedCount > 0 && !expanded && <Typography
                className={styles.alertsCountCircle}
              >{triggered.length - acknowledgedCount}</Typography>
              }
              <Typography
                style={{ marginLeft: (triggered.length - acknowledgedCount > 0 && !expanded) ? "0.4em" : 0 }}
              >
                {name}
              </Typography>
            </HeaderTypography>
            <MoreHorizIcon
              sx={{
                opacity: 0.5,
              }}
            />
          </CardContentBox>
          {!expanded
            &&
            triggered
            &&
            triggered.length > 0
            &&
            <TriggeredAlertButton
              variant={acknowledgedCount < triggered.length ? "contained" : "outlined"}
              onClick={() => setExpanded(prev => !prev)}
            >
              {
                acknowledgedCount < triggered.length ?
                  `View New Triggered Alert`
                  :
                  `View Triggered Alert`
              }
            </TriggeredAlertButton>}
          {
            !triggered.length && !expanded && <Typography>No alerts have been triggered.</Typography>
          }
          <TriggeredAlertsStepper
            alert={alert}
            triggered={triggered}
            expanded={expanded}
            handleShipIconClick={handleShipIconClick}
            acknowledgedCount={acknowledgedCount}
          />
        </CardContent>
        <CardActions>
          <Box
            className={styles.alertCardActions}
          >
            <StyledTypographyButton
              onClick={closeAlert}
            >{expanded ? "Show Less" : "Show More"}
            </StyledTypographyButton>
            <StyledTypographyButton
              onClick={(e) => editAlert(e, alert)}
            >Edit Alert</StyledTypographyButton>
          </Box>
        </CardActions>
      </StyledCard>
    </div>
  )
}

export default AlertCard;

