import { MainMapProvider } from "../../../context/useCurrentMapState";
import { GlobalFiltersProvider } from "../../../context/useGlobalFilter";
import { LayersProvider } from "../../../context/useLayers";
import { ShipDetailsProvider } from "../../../context/useShipDetails";
import { CreateAlertsProvider } from "../../../context/CreateAlertContext";
import { ViewAlertsProvider } from "../../../context/ViewAlertsContext";
import { ShipFiltersProvider } from "../MapComponents/ShipFilters/useShipFilters";
import { SatelliteTimelineProvider } from "../ShipDetails/hooks/useSatelliteImageTimeline";

interface IProviderLayers {
  children: React.ReactNode;
}

const MapProviderLayers = ({ children }: IProviderLayers) => {
  return (
    <GlobalFiltersProvider>
      <MainMapProvider>
        <ShipFiltersProvider>
          <SatelliteTimelineProvider>
            <ViewAlertsProvider>
            <CreateAlertsProvider>
            <ShipDetailsProvider>
              <LayersProvider>{children}</LayersProvider>
            </ShipDetailsProvider>
            </CreateAlertsProvider>
            </ViewAlertsProvider>
          </SatelliteTimelineProvider>
        </ShipFiltersProvider>
      </MainMapProvider>
    </GlobalFiltersProvider>
  );
};

export default MapProviderLayers;
