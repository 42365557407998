// Ship triggers component
import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import styles from "./triggers.module.scss"
import { RadioGroup, Typography } from '@mui/material';
import { useCreateAlerts } from '../../../../../../../context/CreateAlertContext';
import {
  StyledRadio,
  StyledFormControlLabel,
  StyledDiv,
  DividerComponent
} from './ShipTriggersStyled';


export const triggersList = [
  {
    label: "Optical detection",
    enum: "detections"
  },
  {
    label: "Spoofing event",
    enum: "spoofing"
  },
  {
    label: "AIS on",
    enum: "ais_on"
  },
  {
    label: "AIS off",
    enum: "ais_off"
  },
  {
    label: "STS (AIS)",
    enum: "ais_sts"
  },
  {
    label: "STS (optical)",
    enum: "optical_bunkering"
  },
  {
    label: "Flag change",
    enum: "ais_flag_change"
  },

  {
    label: "Ownership change",
    enum: "ownership_change"
  }
]

const ShipTriggers: React.FC = () => {

  const { trigger, handleAddTrigger } = useCreateAlerts();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target).value;

    const valoueObj = triggersList.filter(trigger => trigger.enum === value)[0];
    if (valoueObj) {
      handleAddTrigger(valoueObj);
    }
  };


  return (
    <Box>
      <StyledDiv
      >
        <Typography
          component="div"
          sx={{ paddingLeft: "0.5em" }}
        >
          Select an alert trigger:
        </Typography>
      </StyledDiv>
      <DividerComponent />
      <FormControl component="fieldset" variant="standard">
        <RadioGroup
          value={trigger}
          onChange={handleChange}
        >
          <div className={styles.container}>
            {
              triggersList.map((trigger, index) => {
                return (
                  <StyledFormControlLabel
                    className={styles.item}
                    value={trigger.enum}
                    control={
                      <StyledRadio
                      />
                    }
                    label={trigger.label}
                  />
                )
              }
              )
            }
          </div>
        </RadioGroup>
      </FormControl>
    </Box>
  )
};

export default ShipTriggers;

