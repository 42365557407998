import React from "react";
import SYNMAX_LOGO from "../../assets/synmax-logo.svg";
import styles from "../Auth/Auth.module.scss";
import { Navigate, Outlet } from "react-router";
import useFireBaseAuth from "../../context/useFireBaseAuth";

const MFAWrapper = () => {
  const { getCurrentUserLoggedIn } = useFireBaseAuth();
  const currentLoggedInUser = getCurrentUserLoggedIn();
  const isMFAVerified = localStorage.getItem("isMFAVerified");

  if (currentLoggedInUser && isMFAVerified === "true") {
    return <Navigate to="theia" />;
  } else if (!currentLoggedInUser) {
    return <Navigate to="login" />;
  }

  return (
    <div className={styles.loginMain}>
      <div className={styles.loginOverlay}></div>
      <div className={styles.loginWrap}>
        <div style={{ transform: "translateY(-800%)" }}>
          <img src={SYNMAX_LOGO} alt="synmax-logo" />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default MFAWrapper;
