import InputLabel from "@mui/material/InputLabel/InputLabel";
import styled from "styled-components";
import OutlinedInput from "@mui/material/OutlinedInput/OutlinedInput";
import { FormHelperText, IconButton, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import usePasswordInput from "./usePasswordInput";
import { CSSProperties } from "react";

interface PasswordFieldProps {
  errorMessage?: string;
}

export interface InputContainerProps {
  inputStyle?: React.CSSProperties;
}

const PasswordInputField = styled(OutlinedInput)({
  height: "32px",
  backgroundColor: "#00000080",
  borderColor: "#ffffff40",
  color: "#FFF",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ffffff40",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ffffff40",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff !important",
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FA5849",
  },
  "& .MuiInputBase-input": {
    color: "#FFF",
  },
});

const PasswordField = styled.div<PasswordFieldProps>`
  ${({ errorMessage }) => errorMessage && `min-height: 56px;`}
`;

export const InputContainer = styled.div<InputContainerProps>`
  ${({ inputStyle }) => inputStyle && { ...inputStyle }}}
`;

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: "#FFF",
  marginTop: "4px",
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
}));

const PasswordInput: React.FC<{
  currentPassword: string;
  setCurrentPassword: (password: string) => void;
  label: string;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: boolean;
  errorMessage?: string;
  inputStyle?: CSSProperties;
  placeholder?: string;
}> = ({
  label,
  currentPassword,
  setCurrentPassword,
  onBlur,
  onFocus,
  error,
  errorMessage,
  inputStyle,
  placeholder,
}) => {
  const { handleClickShowPassword, showPassword, handleChange } =
    usePasswordInput({ setCurrentPassword });

  const handleOnBlur = (): void => {
    if (onBlur) onBlur();
  };

  const handleOnFocus = (): void => {
    if (onFocus) onFocus();
  };

  return (
    <InputContainer inputStyle={inputStyle}>
      <InputLabel
        htmlFor="outlined-adornment-password"
        sx={{ color: "#FFF", paddingBottom: "16px" }}
        shrink={false}
      >
        {label}
      </InputLabel>
      <PasswordField errorMessage={errorMessage}>
        <PasswordInputField
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          placeholder={placeholder}
          onChange={handleChange}
          notched={false}
          value={currentPassword}
          endAdornment={
            currentPassword.length > 0 ? (
              <InputAdornment position="end">
                <IconButton
                  sx={{ color: "#FFF" }}
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : null
          }
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          error={error}
          fullWidth
        />
        {error && errorMessage && (
          <StyledFormHelperText>
            <CloseIcon
              sx={{
                fontSize: "12px",
                color: "red",
              }}
            />
            {errorMessage}
          </StyledFormHelperText>
        )}
      </PasswordField>
    </InputContainer>
  );
};

export default PasswordInput;
