import { useEffect, useRef, useState } from "react";

const useColorPicker = () => {
  const [isPopupVisible, setPopupVisible] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const togglePopup = () => {
    setPopupVisible((isVisible) => !isVisible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target;
    if (
      popupRef.current &&
      target &&
      target instanceof Node &&
      !popupRef.current.contains(target)
    ) {
      setPopupVisible(false);
    }
  };

  useEffect(() => {
    const handleClickOutsideRef = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    document.addEventListener("mousedown", handleClickOutsideRef);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideRef);
    };
  }, []);

  return {
    isPopupVisible,
    popupRef,
    togglePopup,
  };
};

export default useColorPicker;
