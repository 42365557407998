import { useEffect, useState } from "react";
import { OSInteligenceItem, getAllWebcams } from "./model";
import { getTodaysDate } from "../../../../utils/utils";
import useCurrentMapState from "../../../../context/useCurrentMapState";

const useOSWebcams = (): {
  oSIntelligenceData: OSInteligenceItem[];
  webcamDataIsLoading: boolean;
} => {
  const [oSIntelligenceData, setOSInteligenceData] = useState<
    OSInteligenceItem[]
  >([]);
  const [webcamDataIsLoading, setWebcamDataIsLoading] = useState(true);

  const { handleWebcamSideBarSelect } = useCurrentMapState();

  useEffect(() => {
    const fetchOSInteligenceList = async () => {
      const currentDate = getTodaysDate();
      const webcams = await getAllWebcams({ date: currentDate });
      webcams.sort(
        (camA, camB) =>
          new Date(camB.lastUpdated).getTime() -
          new Date(camA.lastUpdated).getTime(),
      );
      setOSInteligenceData(webcams);
      setWebcamDataIsLoading(false);
    };
    fetchOSInteligenceList();
  }, []);

  useEffect(() => {
    // remove icon from map layer when sidebar is closed
    return () => {
      handleWebcamSideBarSelect(null);
    };
  }, []);

  return { oSIntelligenceData, webcamDataIsLoading };
};

export default useOSWebcams;
