import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import styles from "../ShipDetails.module.scss";
import useSatelliteImageTimeline from "../hooks/useSatelliteImageTimeline";
import { ShipImages } from "../../MainMap";
import moment from "moment";
import { useState } from "react";

interface ISatelliteImage {
  shipDetailTabValue: any;
  hoveredId: any;
  setHoveredId: (id: number | null) => void;
  handleSatelliteImageClick: (id: number, name: string) => void;
}

const DESCENDING_DATE = "DESCENDING_DATE";
const ASCENDING_DATE = "ASCENDING_DATE";

const SatelliteImage = ({
  shipDetailTabValue,
  hoveredId,
  setHoveredId,
  handleSatelliteImageClick,
}: ISatelliteImage) => {
  const { isSatelliteImageLoading, satelliteImageTimeline } =
    useSatelliteImageTimeline();

  const [sortBy, setSortBy] = useState<string>(DESCENDING_DATE);

  const handleChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);
  };

  const displaySortedImages = () => {
    if (
      Object.keys(satelliteImageTimeline).length > 0 &&
      shipDetailTabValue in satelliteImageTimeline
    ) {
      if (sortBy === ASCENDING_DATE) {
        return satelliteImageTimeline[shipDetailTabValue].sort(
          (a, b) =>
            new Date(a.acquired || 0).getTime() -
            new Date(b.acquired || 0).getTime(),
        );
      } else if (sortBy === DESCENDING_DATE) {
        return satelliteImageTimeline[shipDetailTabValue].sort(
          (a, b) =>
            new Date(b.acquired || 0).getTime() -
            new Date(a.acquired || 0).getTime(),
        );
      }
    }
    return [];
  };

  return (
    <Box sx={{ padding: "13px" }}>
      <Typography className={styles.satelliteImageInfo}>
        Select an satellite image to view ship’s location at the imagery’s
        captured time and its details.
      </Typography>
      <FormControl fullWidth>
        <InputLabel
          className={styles.satelliteSortBy}
          id="sort-by-select-label"
        >
          Sort By
        </InputLabel>
        <Select
          labelId="sort-by-select-label"
          id="sort-byselect"
          value={sortBy}
          label="Sort By"
          onChange={handleChange}
          className={styles.satelliteSortBy}
        >
          <MenuItem value={ASCENDING_DATE}>Ascending by Date</MenuItem>
          <MenuItem value={DESCENDING_DATE}>Descending by Date</MenuItem>
        </Select>
      </FormControl>
      <Box className={styles.imageContainer}>
        {isSatelliteImageLoading ? (
          <CircularProgress className={styles.loader} />
        ) : (
          displaySortedImages().map((ship: ShipImages, index: number) => (
            <Box
              key={index}
              sx={{
                borderRadius: "4px",
                backgroundColor:
                  hoveredId === +ship?.object_id ? "#00A3E34D" : "transparent",
              }}
            >
              <Box sx={{ padding: "8px" }}>
                <Box
                  sx={{ borderRadius: "4px" }}
                  onMouseEnter={() => {
                    setHoveredId(+ship?.object_id);
                  }}
                  onMouseLeave={() => setHoveredId(null)}
                  onClick={() =>
                    handleSatelliteImageClick(+ship?.object_id, ship?.name)
                  }
                >
                  <img
                    className={styles.satelliteImage}
                    src={ship.image}
                    width={"100%"}
                    height={226}
                    alt="ship"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={styles.shipDetail}
                >
                  <label className={styles.detailLabel}>
                    Image Captured Time
                  </label>
                  <span className={styles.detailValue}>
                    {moment(ship.acquired).toISOString() ?? "No Info"}
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 5px 0 0",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.shipDetail}
                  >
                    <label className={styles.detailLabel}>Latitude</label>
                    <span className={styles.detailValue}>
                      {ship.latitude ?? "No Info"}
                    </span>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.shipDetail}
                  >
                    <label className={styles.detailLabel}>Longitude</label>
                    <span className={styles.detailValue}>
                      {ship.longitude ?? "No Info"}
                    </span>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.shipDetail}
                  >
                    <label className={styles.detailLabel}>OID</label>
                    <span className={styles.detailValue}>
                      {ship.object_id ?? "No Info"}
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};
export default SatelliteImage;
