import moment from "moment";

/**
 * Converts a date to UTC moment and sets it to start of day
 * @param date - Date or string to convert
 * @returns {moment.Moment} Moment object set to start of day in UTC
 */
export const getUTCStartOfDay = (date: Date | string) => {
  return moment.utc(new Date(date)).startOf("day");
};

/**
 * Converts a date to UTC moment and sets it to end of day
 * @param date - Date or string to convert
 * @returns {moment.Moment} Moment object set to end of day in UTC
 */
export const getUTCEndOfDay = (date: Date | string) => {
  return moment.utc(new Date(date)).endOf("day");
};

/**
 * Formats a date to YYYY-MM-DD HH:mm:ss format in UTC
 * @param date - Moment object to format
 * @returns {string} Formatted date string
 */
export const formatUTCDateTime = (date: moment.Moment) => {
  return date.format("YYYY-MM-DD HH:mm:ss");
};

/**
 * Formats a date to MM/DD/YYYY format in UTC
 * @param date - Date to format
 * @returns {string} Formatted date string
 */
export const formatUTCDate = (date: Date) => {
  return moment(date.valueOf()).utc().format("MM/DD/YYYY");
};

/**
 * Calculates the slider label for a given value and start epoch
 * @param value - Slider value
 * @param startEpochDate - Start epoch timestamp in seconds
 * @returns {string} Formatted date/time string
 */
export const calculateSliderLabel = (value: number, startEpochDate: number) => {
  const timestamp = startEpochDate + value * 15 * 60;
  return moment.utc(timestamp * 1000).format("YYYY-MM-DD HH:mm:ss");
};

/**
 * Generates time segments for data fetching
 * @param startDate - Start date as moment object
 * @param endDate - End date as moment object
 * @param segmentHours - Number of hours per segment
 * @returns {Array<{start: string, end: string, isLastSegment: boolean}>} Array of time segments
 */
export const generateTimeSegments = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  segmentHours: number,
) => {
  const start = getUTCStartOfDay(startDate.toDate());
  const end = getUTCEndOfDay(endDate.toDate());
  const totalSegments = Math.ceil(end.diff(start, "hours") / segmentHours);

  let segments = [];
  let currentStart = start.clone();
  let currentEnd = start
    .clone()
    .add(segmentHours, "hours")
    .subtract(1, "seconds");
  let segmentIndex = 0;

  while (currentStart.isBefore(end)) {
    segmentIndex++;
    const isLastSegment = segmentIndex === totalSegments;

    segments.push({
      start: formatUTCDateTime(currentStart),
      end: isLastSegment
        ? formatUTCDateTime(end)
        : formatUTCDateTime(currentEnd),
      isLastSegment,
    });

    currentStart = currentEnd.clone().add(1, "seconds");
    currentEnd = currentStart
      .clone()
      .add(segmentHours, "hours")
      .subtract(1, "seconds");
  }

  return segments;
};

/**
 * Calculates slider values based on date range
 * @param startDate - Start date
 * @param endDate - End date
 * @returns {{startEpoch: number, endEpoch: number, sliderMax: number}} Slider range values
 */
export const calculateSliderRange = (startDate: Date, endDate: Date) => {
  const startDateStr = getUTCStartOfDay(startDate);
  const endDateStr = getUTCEndOfDay(endDate);

  const startEpoch = startDateStr.unix();
  const endEpoch = endDateStr.unix();
  const totalSeconds = endEpoch - startEpoch;
  const sliderMax = Math.ceil(totalSeconds / (15 * 60));

  return {
    startEpoch,
    endEpoch,
    sliderMax,
  };
};

/**
 * Distributes polygon data into time slots
 * @param polygonData - Array of polygon data objects
 * @param startEpoch - Start epoch timestamp in seconds
 * @param interval - Time interval in seconds (default: 15 minutes)
 * @returns {{slots: any[], lastPopulatedIndex: number}} Distributed data and last populated slot index
 */
export const distributeDataIntoTimeSlots = (
  polygonData: any[],
  startEpoch: number,
  interval: number = 15 * 60,
) => {
  const sortedData = [...polygonData].sort(
    (a: any, b: any) => a.timestamp / 1000 - b.timestamp / 1000,
  );

  const slots: any[] = [];
  const set = new Set();
  let lastPopulatedIndex = -1;

  sortedData.forEach((data: any) => {
    const timestamp = data.timestamp / 1000;
    const slotIndex = Math.floor((timestamp - startEpoch) / interval);

    if (slotIndex >= 0) {
      if (!slots[slotIndex]) {
        slots[slotIndex] = [];
      }

      if (
        !slots[slotIndex].some(
          (item: any) => item.synmax_ship_id === data.synmax_ship_id,
        )
      ) {
        slots[slotIndex].push(data);
        set.add(data.synmax_ship_id);
        lastPopulatedIndex = Math.max(lastPopulatedIndex, slotIndex);
      }
    }
  });

  return {
    slots,
    lastPopulatedIndex,
  };
};
