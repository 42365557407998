import {
  confirmPasswordReset,
  getAuth,
  verifyPasswordResetCode,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "../../context/SnackbarContext";

const useUpdateForgottenPassword = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfimPassword] = useState<string>("");
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);
  const [passwordError, setErrorMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const oobCode = urlParams.get("oobCode");

  const { setNewSnackBar } = useSnackbar();

  const hanldeSetConfirmPassword = (input: string) => {
    setConfimPassword(input);
  };

  useEffect(() => {
    const validateMatachingPasswords = (): boolean => {
      if (
        confirmPassword.length > newPassword.length ||
        (newPassword.length === confirmPassword.length &&
          newPassword !== confirmPassword)
      ) {
        setErrorMessage("Passwords do not match");
        return false;
      } else {
        setErrorMessage(null);
      }
      return true;
    };
    validateMatachingPasswords();
  }, [newPassword, confirmPassword]);

  const resetUserPassword = async (
    newPassword: string,
    oobCode: string | null,
  ) => {
    const auth = getAuth();

    if (!oobCode) {
      return;
    }

    try {
      // confirm sindle use oobCode
      await verifyPasswordResetCode(auth, oobCode);

      // resetuser password
      await confirmPasswordReset(auth, oobCode, newPassword);

      navigate("/login");
      setNewSnackBar({
        message: "Password has been updated",
        severity: "success",
      });
    } catch (error) {
      console.log("Error during password reset:", error);
      setNewSnackBar({
        message:
          "We are sorry we were unable to update your password, please contact SynMax for assistance",
        severity: "error",
      });
    }
  };

  const handleUserUpdatePassword = async () => {
    await resetUserPassword(newPassword, oobCode);
  };

  return {
    setNewPassword,
    confirmPassword,
    setPasswordIsValid,
    passwordError,
    passwordIsValid,
    hanldeSetConfirmPassword,
    handleUserUpdatePassword,
    newPassword,
  };
};

export default useUpdateForgottenPassword;
