import { HexColorInput, HexColorPicker } from "react-colorful";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import styled from "styled-components";
import useColorPicker from "./useColorPicker";

interface IColorPickerProp {
  color: string;
  setColor: (value: string) => void;
}

const StyledHexColorInput = styled(HexColorInput)`
  max-width: 90px;
  color: #ffffff;
  background-color: #00000080;
  border: 1px solid transparent;
  outline: none;
  border-radius: 4px;
  padding: 3px;

  &:focus {
    border: 1px solid white;
  }
`;

const StyledBox = styled(Box)`
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 4px;
  margin-right: 1px;

  &:hover {
    transform: scale(1.1);
  }
`;

const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledColorPicker = styled(HexColorPicker)`
  position: absolute !important;
  top: calc(8%);
  left: calc(30%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 51;
`;

const ColorPicker = ({ color, setColor }: IColorPickerProp) => {
  const { isPopupVisible, popupRef, togglePopup } = useColorPicker();

  return (
    <ColorPickerContainer ref={popupRef}>
      <StyledBox bgcolor={color} onClick={togglePopup} />
      <ExpandMoreIcon
        sx={{ color: "#FFF", cursor: "pointer" }}
        onClick={togglePopup}
      />
      {isPopupVisible && (
        <StyledColorPicker color={color} onChange={setColor} />
      )}
      <StyledHexColorInput color={color} onChange={setColor} />
    </ColorPickerContainer>
  );
};

export default ColorPicker;
