import { Navigate, useRoutes } from "react-router-dom";
import SignIn from "../pages/Auth/SignIn/SignIn";
import SignUp from "../pages/Auth/SignUp/SignUp";
import EmailCheck from "../pages/Auth/EmailCheck/EmailCheck";
import MFA from "../pages/MFA/MFA";
import AuthWrapper from "../pages/Auth/AuthWrapper";
import Theia from "../pages/Theia/Theia";
import LayoutWrapper from "../layout/LayoutWrapper/LayoutWrapper";
import Spinner from "../components/Spinner/Spinner";
import MFAWrapper from "../pages/MFA/MFAWrapper";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import ChangePasswordSignIn from "../components/ChangePasswordSignIn/ChangePasswordSignIn";
import ForgetPassword from "../pages/Auth/ForgetPassword/ForgetPassword";
import UpdateForgottenPassword from "../components/UpdateForgottenPassword/UpdateForgottenPassword";
import Admin from "../pages/Admin/Admin";
import useFireBaseAuth from "../context/useFireBaseAuth";

export default function Routes() {
  const isMFAVerified = localStorage.getItem("isMFAVerified");
  const { showLoader } = useSelector(
    (state: RootState) => state.globalTheiaState,
  );

  const { currentUser } = useFireBaseAuth();
  const { role } = currentUser;
  const { getCurrentUserLoggedIn } = useFireBaseAuth();
  const currentLoggedInUser = getCurrentUserLoggedIn();

  const routes = useRoutes([
    {
      element: <AuthWrapper />,
      children: [
        { path: "login", element: <SignIn /> },
        { path: "register", element: <SignUp /> },
        { path: "resend-email", element: <EmailCheck /> },
        {
          path: "change-password",
          element: <ChangePasswordSignIn />,
        },
        {
          path: "forgot-password",
          element: <ForgetPassword />,
        },
        {
          path: "update-forgot-password",
          element: <UpdateForgottenPassword />,
        },
        ...(role === "admin"
          ? [
              {
                path: "admin",
                element: <Admin />,
              },
            ]
          : []),
      ],
    },
    {
      element: <MFAWrapper />,
      children: [{ path: "mfa", element: <MFA /> }],
    },
    {
      element: <LayoutWrapper />,
      children: [
        {
          path: "theia",
          element:
            isMFAVerified === "true" && currentLoggedInUser ? (
              <Theia />
            ) : isMFAVerified === "false" ? (
              <Navigate to="/mfa" />
            ) : (
              !showLoader && <Spinner />
            ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/login" /> },
  ]);

  return routes;
}
