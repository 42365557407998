import ShipBowIcon from "../../assets/icons/shipBowIcon.svg";
import SatelliteIcon from "../../assets/icons/satelliteIcon.svg";
import OSInteligenceIcon from "../../assets/icons/OSIntelligence.svg";
import Camcorder from "../../assets/icons/Camcorder.svg";
import CamcorderSidebar from "../../assets/icons/CamcorderSidebar.svg";
import Pin from "../../assets/icons/Pin.svg";
import OSIntMenu from "../../assets/icons/OSIntMenu.svg";
import ICCCCS from "../../assets/icons/ICCCCS.svg";

export type IconType =
  | "shipBow"
  | "satellite"
  | "OSInteligence"
  | "camcorder"
  | "camcorderSideBar"
  | "ICCCCS"
  | "pin"
  | "osIntMenu";

const SynMaxIcon: React.FC<{ icon: IconType; size: number }> = ({
  icon,
  size,
}) => {
  function getIcon() {
    switch (icon) {
      case "shipBow": {
        return ShipBowIcon;
      }
      case "satellite": {
        return SatelliteIcon;
      }
      case "OSInteligence": {
        return OSInteligenceIcon;
      }
      case "camcorder": {
        return Camcorder;
      }
      case "camcorderSideBar": {
        return CamcorderSidebar;
      }
      case "ICCCCS": {
        return ICCCCS;
      }
      case "pin": {
        return Pin;
      }
      case "osIntMenu": {
        return OSIntMenu;
      }
    }
  }

  return <img src={getIcon()} alt={icon} height={`${size}px`} />;
};

export default SynMaxIcon;
