import Button from "@mui/material/Button";
import { calculateTimeSince } from "../../../../utils/utils";
import SynMaxIcon from "../../../Shared/SynMaxIcon";
import styles from "./OSWebcamListItem.module.scss";
import WebcamThumbnailImage from "../WebcamThumbnailImage/WebcamThumbnailImage";
import { OSInteligenceItem } from "../OSWebcams/model";
import useCurrentMapState from "../../../../context/useCurrentMapState";

const OSWebcamListItem: React.FC<{ osIntelItem: OSInteligenceItem }> = ({
  osIntelItem,
}) => {
  const { mapRef, handleWebcamSideBarSelect } = useCurrentMapState();
  const timeSinceLastUpdate = calculateTimeSince(osIntelItem.lastUpdated);

  const handleGoToMapClick = (): void => {
    handleWebcamSideBarSelect(osIntelItem);
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [osIntelItem.longitude, osIntelItem.latitude],
        zoom: 6,
      });
    }
  };

  return (
    <div className={styles.OSInteligenceListItem}>
      <div className={styles.osIntelItemHeader}>
        <SynMaxIcon icon="camcorder" size={12} />
        <div className={styles.osItemTitle}>Live Cam - {osIntelItem.title}</div>
        <div className={styles.osItemTime}>{timeSinceLastUpdate}</div>
      </div>
      <div className={styles.webCamInfo}>
        <WebcamThumbnailImage url={osIntelItem.description} />
        <div className={styles.osItemDescription}>
          <div className={styles.osItemInfo}>Screenshot Updated</div>
          <Button
            variant="text"
            type="button"
            className={styles.goToMapButton}
            onClick={handleGoToMapClick}
          >
            Go to Map
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OSWebcamListItem;
