import styled from "styled-components";
import { Radio,FormControlLabel } from "@mui/material";


export const StyledRadio = styled(Radio)`
  color: white; // Unselected color 
  &.Mui-checked {
    // color: 'blue'; // Uncomment if you want a color change when checked
  }
  & .MuiSvgIcon-root {
    border-radius: 50%; // Ensure rounded appearance
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
 & .MuiFormControlLabel-label {
    font-size: 0.875rem; // Set the font size here
  }
`

export const StyledDiv = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
`

export const DividerComponent = styled.div`
height:0.1em;
width:100%;
background-color: rgba(57, 60, 86, 1);
margin: 1em 0;
`