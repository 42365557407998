import { createContext, useContext, useEffect, useState } from "react";
import {
  ProviderFilters,
  SHIP_DATA_SOURCE,
  SHIP_SERVICE_PROVIDER_LIST,
} from "../../../../utils/Constants";
import { FilterParameters } from "../FilterArea/FilterArea";
import { defaultImplementation } from "../../../../utils/utils";

interface IShipFiltersProviderProps {
  children: React.ReactNode;
}

interface IShipFiltersContext {
  setProviderList: (value: string[]) => void;
  providerData: ProviderFilters[];
  setProviderData: (value: ProviderFilters[]) => void;
  setShipDataSourceList: (value: string[]) => void;
  setShipDataSourceStatus: (value: ProviderFilters[]) => void;
  setCountryFilter: (value: { code: string; label: string }) => void;
  setShipTypeFilters: (value: string[]) => void;
  setShipLengthFilter: (value: number[]) => void;
  setShipWidthFilter: (value: number[]) => void;
  setShowOnlySanctionedShips: (value: boolean) => void;
  setFootpirintSpoofingISEnabled: (value: boolean) => void;
  setOpticalSpoofingISEnabled: (value: boolean) => void;
  setAisSpoofingIsEnabled: (value: boolean) => void;
  setIsMovingFiler: (value: string) => void;
  setHeadingFilter: (value: number[]) => void;
  setNoIMOShips: (value: boolean) => void;
  setCurrentSelectedShipColor: (value: string) => void;
  setShipSubTypeFilters: (value: string[]) => void;
  shipSubTypeFilters: string[];
  providerList: string[];
  currentSelectedShipColor: string;
  countryFilter: { code: string; label: string };
  shipLengthFilter: number[];
  shipWidthFilter: number[];
  noImoShips: boolean;
  showOnlySanctionedShip: boolean;
  isMovingFilter: string;
  headingFilter: number[];
  shipTypeFilters: string[];
  aisSpoofingIsEnabled: boolean;
  footprintSpoofingIsEnabled: boolean;
  opticalSpoofingIsEnabled: boolean;
  shipDataSourceList: string[];
  shipDataSourceStatus: ProviderFilters[];
  setCurrentFilter: (value: FilterParameters) => void;
  setCurrentEventTypeFilter: (value: string) => void;
}

const ShipFiltersContext = createContext<IShipFiltersContext>({
  setProviderList: defaultImplementation,
  setProviderData: defaultImplementation,
  setShipDataSourceList: defaultImplementation,
  setShipDataSourceStatus: defaultImplementation,
  setCountryFilter: defaultImplementation,
  setShipTypeFilters: defaultImplementation,
  setShipLengthFilter: defaultImplementation,
  setShipWidthFilter: defaultImplementation,
  setShowOnlySanctionedShips: defaultImplementation,
  setFootpirintSpoofingISEnabled: defaultImplementation,
  setOpticalSpoofingISEnabled: defaultImplementation,
  setAisSpoofingIsEnabled: defaultImplementation,
  setIsMovingFiler: defaultImplementation,
  setHeadingFilter: defaultImplementation,
  setNoIMOShips: defaultImplementation,
  setCurrentSelectedShipColor: defaultImplementation,
  setShipSubTypeFilters: defaultImplementation,
  shipSubTypeFilters: [],
  providerData: [],
  providerList: [],
  currentSelectedShipColor: "",
  countryFilter: { code: "", label: "" },
  shipLengthFilter: [],
  shipWidthFilter: [],
  noImoShips: false,
  showOnlySanctionedShip: false,
  isMovingFilter: "none",
  headingFilter: [],
  shipTypeFilters: [],
  aisSpoofingIsEnabled: false,
  footprintSpoofingIsEnabled: false,
  opticalSpoofingIsEnabled: false,
  shipDataSourceList: [],
  shipDataSourceStatus: [],
  setCurrentFilter: defaultImplementation,
  setCurrentEventTypeFilter: defaultImplementation,
});

const useShipFilters = (
  currentFilter?: FilterParameters,
  currentEventTypeFilter?: string | null,
) => {
  const context = useContext<IShipFiltersContext>(ShipFiltersContext);
  const { setCurrentFilter, setCurrentEventTypeFilter } = context;

  useEffect(() => {
    if (currentFilter && currentEventTypeFilter) {
      setCurrentFilter(currentFilter);
      setCurrentEventTypeFilter(currentEventTypeFilter || "");
    }
  }, [currentFilter, currentEventTypeFilter]);

  if (!context) {
    throw new Error("useShipFilters must be used with a ShipFiltersProvider");
  }
  return context;
};

export const ShipFiltersProvider = ({
  children,
}: IShipFiltersProviderProps) => {
  const [currentFilter, setCurrentFilter] = useState<FilterParameters | null>(
    null,
  );
  const [currentEventTypeFilter, setCurrentEventTypeFilter] =
    useState<string>("");

  const [currentSelectedShipColor, setCurrentSelectedShipColor] =
    useState<string>("");

  const [countryFilter, setCountryFilter] = useState<{
    code: string;
    label: string;
  }>({ code: "", label: "" });
  // }>("currentFilter?.country");

  // This is to handle backwards compatibility with the old filter
  const [shipTypeFilters, setShipTypeFilters] = useState<string[]>([]);

  const [shipSubTypeFilters, setShipSubTypeFilters] = useState<string[]>(
    currentFilter?.shipSubType ? currentFilter?.shipSubType : [],
  );

  const [shipLengthFilter, setShipLengthFilter] = useState<number[]>(
    currentFilter?.length || [],
  );

  const [shipWidthFilter, setShipWidthFilter] = useState<number[]>(
    currentFilter?.width || [],
  );

  const [headingFilter, setHeadingFilter] = useState<number[]>(
    currentFilter?.heading || [0, 360],
  );

  const [noImoShips, setNoIMOShips] = useState<boolean>(
    currentFilter?.noImoShips || false,
  );

  const [showOnlySanctionedShip, setShowOnlySanctionedShips] =
    useState<boolean>(currentFilter?.sanctionedShips || false);

  const [footprintSpoofingIsEnabled, setFootpirintSpoofingISEnabled] =
    useState<boolean>(
      currentFilter?.subType && currentFilter?.subType["footprint-spoofing"]
        ? currentFilter?.subType["footprint-spoofing"]
        : false,
    );

  const [opticalSpoofingIsEnabled, setOpticalSpoofingISEnabled] =
    useState<boolean>(
      currentFilter?.subType && currentFilter?.subType["optical-spoofing"]
        ? currentFilter?.subType["optical-spoofing"]
        : false,
    );

  const [aisSpoofingIsEnabled, setAisSpoofingIsEnabled] = useState(
    currentFilter?.subType && currentFilter?.subType["ais-spoofing"]
      ? currentFilter?.subType["ais-spoofing"]
      : false,
  );

  const [isMovingFilter, setIsMovingFiler] = useState(
    currentFilter?.isMoving || "none",
  );

  const [providerList, setProviderList] = useState<string[]>([]);

  const [providerData, setProviderData] = useState<ProviderFilters[]>([]);

  const defaultDataSource = SHIP_DATA_SOURCE.map((item) => item.label);

  const [shipDataSourceList, setShipDataSourceList] =
    useState<string[]>(defaultDataSource);
  const [shipDataSourceStatus, setShipDataSourceStatus] =
    useState(SHIP_DATA_SOURCE);

  // This is a bad practice... I should not be using useEffect to set the state
  // Time constraint
  useEffect(() => {
    if (currentFilter) {
      setCurrentSelectedShipColor(currentFilter?.markerColor || "");
      setCountryFilter(currentFilter?.country);
      setShipLengthFilter(currentFilter?.length || []);
      setShipWidthFilter(currentFilter?.width || []);
      setNoIMOShips(currentFilter?.noImoShips || false);
      setShowOnlySanctionedShips(currentFilter?.sanctionedShips || false);
      setFootpirintSpoofingISEnabled(
        currentFilter?.subType && currentFilter?.subType["footprint-spoofing"]
          ? currentFilter?.subType["footprint-spoofing"]
          : false,
      );
      setOpticalSpoofingISEnabled(
        currentFilter?.subType && currentFilter?.subType["optical-spoofing"]
          ? currentFilter?.subType["optical-spoofing"]
          : false,
      );
      setAisSpoofingIsEnabled(
        currentFilter?.subType && currentFilter?.subType["ais-spoofing"]
          ? currentFilter?.subType["ais-spoofing"]
          : false,
      );
      setIsMovingFiler(currentFilter?.isMoving || "none");
      setHeadingFilter(currentFilter?.heading || [0, 360]);
      setShipTypeFilters(
        Array.isArray(currentFilter?.type)
          ? (currentFilter?.type as string[])
          : currentFilter?.type && currentFilter?.type !== "None"
            ? [currentFilter?.type as string]
            : [],
      );

      setShipSubTypeFilters(
        currentFilter?.shipSubType ? currentFilter?.shipSubType : [],
      );
    }
  }, [currentFilter]);

  useEffect(() => {
    const sentinel1 = providerData.find(
      (provider) => provider.label === "Sentinel-1",
    );
    if (shipDataSourceList.includes("SAR") && sentinel1) {
      sentinel1.disabled = false;
    } else if (sentinel1) {
      sentinel1.checked = false;
      sentinel1.disabled = true;
    }

    const sentinel2 = providerData.find(
      (provider) => provider.label === "Sentinel-2",
    );
    const planetscope = providerData.find(
      (provider) => provider.label === "PlanetScope",
    );

    if (shipDataSourceList.includes("Optical") && sentinel2 && planetscope) {
      sentinel2.disabled = false;
      planetscope.disabled = false;
    } else if (sentinel2 && planetscope) {
      sentinel2.disabled = true;
      planetscope.disabled = true;
      sentinel2.checked = false;
      planetscope.checked = false;
    }
    if (sentinel1 && sentinel2 && planetscope) {
      const updatedProviderData: ProviderFilters[] = [
        sentinel1,
        sentinel2,
        planetscope,
      ];
      if (updatedProviderData !== undefined) {
        setProviderData(updatedProviderData);
        setProviderList(
          updatedProviderData
            .filter((provider) => provider.checked)
            .map((provider) => provider.label),
        );
      }
    }
  }, [shipDataSourceList]);

  useEffect(() => {
    const setDataSourceAndProviderValues = () => {
      setProviderList(
        SHIP_SERVICE_PROVIDER_LIST.filter(
          (value) => currentFilter?.providerFilters[value.value],
        ).map((filter) => filter.label),
      );
      setProviderData(
        SHIP_SERVICE_PROVIDER_LIST.map((item) => {
          return {
            ...item,
            checked: currentFilter?.providerFilters[item.value] ?? false,
          };
        }),
      );

      let sarOptionIndex = 0;
      const sarOption = shipDataSourceStatus.find((option, index) => {
        if (option.label === "SAR") {
          sarOptionIndex = index;
          return option;
        }
      });

      let opticalOptionIndex = 0;
      const opticalOption = shipDataSourceStatus.find((option, index) => {
        if (option.label === "Optical") {
          opticalOptionIndex = index;
          return option;
        }
      });

      if (
        !currentFilter?.providerFilters.planetscope &&
        !currentFilter?.providerFilters.sentinel2 &&
        opticalOption
      ) {
        setShipDataSourceList(
          defaultDataSource.filter((label) => label !== "Optical"),
        );
        opticalOption.checked = false;
      } else if (opticalOption) {
        opticalOption.checked = true;
      }

      if (!currentFilter?.providerFilters.sentinel1 && sarOption) {
        setShipDataSourceList(
          defaultDataSource.filter((label) => label !== "SAR"),
        );
        sarOption.checked = false;
      } else if (sarOption) {
        sarOption.checked = true;
      }
      if (currentEventTypeFilter === "dark" && sarOption) {
        sarOption.disabled = true;
        sarOption.checked = false;
        setShipDataSourceList((preVal) =>
          preVal.filter((value: string) => value !== "SAR"),
        );
      } else if (sarOption) {
        sarOption.disabled = false;
      }
      const updatedSource = [...shipDataSourceStatus];
      if (sarOption && opticalOption) {
        updatedSource[sarOptionIndex] = sarOption;
        updatedSource[opticalOptionIndex] = opticalOption;
      }
      setShipDataSourceStatus(updatedSource);
    };

    if (currentFilter?.providerFilters) {
      setDataSourceAndProviderValues();
    }
  }, [currentFilter, currentEventTypeFilter]);

  return (
    <ShipFiltersContext.Provider
      value={{
        setProviderList,
        providerData,
        setProviderData,
        setShipDataSourceList,
        setShipDataSourceStatus,
        setCountryFilter,
        setShipTypeFilters,
        setShipLengthFilter,
        setShipWidthFilter,
        setShowOnlySanctionedShips,
        setFootpirintSpoofingISEnabled,
        setOpticalSpoofingISEnabled,
        setAisSpoofingIsEnabled,
        setIsMovingFiler,
        setHeadingFilter,
        setNoIMOShips,
        setCurrentSelectedShipColor,
        setShipSubTypeFilters,
        setCurrentFilter,
        setCurrentEventTypeFilter,
        shipSubTypeFilters,
        providerList,
        currentSelectedShipColor,
        countryFilter,
        shipLengthFilter,
        shipWidthFilter,
        noImoShips,
        showOnlySanctionedShip,
        isMovingFilter,
        headingFilter,
        shipTypeFilters,
        aisSpoofingIsEnabled,
        footprintSpoofingIsEnabled,
        opticalSpoofingIsEnabled,
        shipDataSourceList,
        shipDataSourceStatus,
      }}
    >
      {children}
    </ShipFiltersContext.Provider>
  );
};

export default useShipFilters;
