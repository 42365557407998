// Import necesary libraries
import React from "react";
import AlertCard from "./AlertCard"
import { Tabs, Box } from "@mui/material";
import { useViewAlerts } from "../../../../context/ViewAlertsContext";
import CircularProgress from '@mui/material/CircularProgress';
import {
  AlertsTabPanelDiv,
  AlertsMainTabsBox,
  BoxContainer,
  TabComponent,
  AlertsCardDiv,
  AlertsCardBox,
  SpinnerContainerBox,
  PlaceholderTypography
} from "./AlertsWrapperStyled";

const AlertsBuilderWrapper = React.lazy(() => import('./AlertsBuilder/AlertsBuilderWrapper'))

// Prop Type for Tab Panel
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface AlertWrapperProps {
  handleShipIconClick: (id: string, f: any) => Promise<void>
}

// Tab Panel Component
function AlertsTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  // Tab Panel Component
  return ((value === index) ?
    <AlertsTabPanelDiv
      key={index}
      hidden={value !== index}
      {...other}
    >
      <Box sx={{ p: 2 }}>{children}</Box>
    </AlertsTabPanelDiv>
    : null)

}


// Alerts Main Tabs Component
function AlertsMainTabs({ comp }: any) {

  const { tabValue, handleTabChange } = useViewAlerts();

  return (
    <AlertsMainTabsBox>
      {/* Tabs Panel for switching between view Alerts and Create Alerts */}
      <BoxContainer>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
          TabIndicatorProps={{
            style: {
              backgroundColor: 'white', // Change slider color to white
            },
          }}
        >
          <TabComponent
            sx={{
              color: '#FFFFFF',
              textTransform: 'none',
              opacity: 0.5,
            }}
            label="My Alerts"
          />
          <TabComponent
            sx={{
              color: '#FFFFFF',
              textTransform: 'none',
              opacity: 0.5,
            }}
            label="Alert Builder"
          />
        </Tabs>
      </BoxContainer>

      {/* Panel 1 for view Alerts */}
      <AlertsTabPanel
        value={tabValue}
        index={0}
      >
        {comp}
      </AlertsTabPanel>

      {/* Panel 2 for Alert Builder */}
      <React.Suspense fallback={<div>Loading...</div>}>
        <AlertsTabPanel value={tabValue} index={1}>
          <AlertsBuilderWrapper />
        </AlertsTabPanel>
      </React.Suspense>
    </AlertsMainTabsBox>
  );
}


// We will use this component to display the alerts.
// This is the parent component for all the alerts components.
// Alerts starts with this component.
const AlertWrapper: React.FC<AlertWrapperProps> = ({ handleShipIconClick }: any) => {

  const { alerts, isAlertsLoading } = useViewAlerts();

  // This is Alert cards component.
  // This will be rendered in the View Alerts Tab.

  const alertCards = <AlertsCardDiv
  >
    {
      isAlertsLoading &&
      <SpinnerContainerBox>
        <CircularProgress sx={{ color: "#ffffff" }} size={50} />
      </SpinnerContainerBox>
    }
    {
      !isAlertsLoading && alerts.map((alert: any) => {
        return <AlertCard
          handleShipIconClick={handleShipIconClick}
          alert={alert}
          triggered={alert.triggered.triggered}
          acknowledgedCount={alert.triggered.acknowledged}
          key={alert.alert_id}
          name={alert.customer_alert_name}
        />
      })
    }
    {
      alerts.length === 0 && !isAlertsLoading && <PlaceholderTypography> No Alerts Found </PlaceholderTypography>
    }
  </AlertsCardDiv>

  return (
    <AlertsCardBox
    >
      <AlertsMainTabs comp={alertCards} />
    </AlertsCardBox>
  )
}

export default AlertWrapper;
