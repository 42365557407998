import { SetStateAction } from "react";
import { ColorResult, HuePicker } from "react-color";
import {
  COUNTRY_CODES,
  SHIP_FILTER_PROVIDER,
  SHIP_MOVING_OPTIONS,
} from "../../../../utils/Constants";
import { ShipSvgs } from "../../../../utils/ShipSvgs";
import SelectCheckbox from "../../../../components/SelectCheckbox/SelectCheckbox";
import { Checkbox, Slider, Autocomplete, Box, Button } from "@mui/material";
import { ChevronLeft, Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./ShipFilters.module.scss";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import useShipFilters from "./useShipFilters";
import {
  FilterParameters,
  ICurrentEventTypeFilterOptions,
} from "../FilterArea/FilterArea";
import ShipTypeFilters from "./ShipTypeFilters";

interface ShipFiltersProps {
  currentEventTypeFilter: string | null;
  setCurrentEventTypeFilter: (value: string | null) => void;
  handleUpdateShipFilters: (
    updatedFilter: ICurrentEventTypeFilterOptions,
  ) => void;
  currentFilter: FilterParameters;
  currentEventTypeFilterOptions: ICurrentEventTypeFilterOptions;
  setCurrentEventTypeFilterOptions: SetStateAction<ICurrentEventTypeFilterOptions>;
}

const ShipFilters = ({
  currentEventTypeFilter,
  setCurrentEventTypeFilter,
  handleUpdateShipFilters,
  currentFilter,
  currentEventTypeFilterOptions,
}: ShipFiltersProps) => {
  const {
    setProviderList,
    providerData,
    setProviderData,
    setShipDataSourceList,
    setShipDataSourceStatus,
    setCountryFilter,
    setShipLengthFilter,
    setShipWidthFilter,
    setShowOnlySanctionedShips,
    setFootpirintSpoofingISEnabled,
    setOpticalSpoofingISEnabled,
    setAisSpoofingIsEnabled,
    setIsMovingFiler,
    setHeadingFilter,
    setNoIMOShips,
    setCurrentSelectedShipColor,
    providerList,
    currentSelectedShipColor,
    countryFilter,
    shipLengthFilter,
    shipWidthFilter,
    noImoShips,
    showOnlySanctionedShip,
    isMovingFilter,
    headingFilter,
    shipTypeFilters,
    aisSpoofingIsEnabled,
    footprintSpoofingIsEnabled,
    opticalSpoofingIsEnabled,
    shipDataSourceList,
    shipDataSourceStatus,
    shipSubTypeFilters,
  } = useShipFilters(currentFilter, currentEventTypeFilter);

  const handleColorUpdate = (item: ColorResult) => {
    setCurrentSelectedShipColor(item.hex);
  };

  const handleChangeShipDataProvider = (event: {
    target: { value: string[] };
  }) => {
    const {
      target: { value },
    } = event;
    setProviderList(value);

    const updatedItems = providerData.map((item) => ({
      ...item,
      checked: event.target.value.includes(item.label),
    }));
    setProviderData(updatedItems);
  };

  const handleChangeShipSource = (event: { target: { value: string[] } }) => {
    const {
      target: { value },
    } = event;
    setShipDataSourceList(value);

    const updatedItems = shipDataSourceStatus.map((item) => ({
      ...item,
      checked: event.target.value.includes(item.label),
    }));
    setShipDataSourceStatus(updatedItems);
  };

  const handleCountryUpdate = (
    event: { stopPropagation: () => void },
    newValue: {
      code: string;
      label: string;
    } | null,
  ) => {
    event.stopPropagation();
    if (newValue !== null) {
      setCountryFilter(newValue);
    }
  };

  const clearCountryCodeFilter = () => {
    setCountryFilter({ code: "", label: "" });
  };

  const handleLengthFilterUpdate = (e: any, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setShipLengthFilter(newValue);
    }
  };

  const handleWidthFilterUpdate = (e: any, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setShipWidthFilter(newValue);
    }
  };

  const handleHeadingFilterChange = (e: any, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setHeadingFilter(newValue);
    }
  };

  const handleNoIMOShipChange = (e: any, newValue: boolean) => {
    setNoIMOShips(newValue);
  };

  const handleShowOnlySanctionedShipChange = (e: any, newValue: boolean) => {
    setShowOnlySanctionedShips(newValue);
  };

  const handleFootprintSpoofingChange = (e: any, newValue: boolean) => {
    setFootpirintSpoofingISEnabled(newValue);
  };

  const handleOpticalSpoofingChange = (e: any, newValue: boolean) => {
    setOpticalSpoofingISEnabled(newValue);
  };

  const handleAisSpoofingChange = (e: any, newValue: boolean) => {
    setAisSpoofingIsEnabled(newValue);
  };

  const handleIsMovingChange = (e: {
    stopPropagation: () => void;
    target: { value: boolean };
  }) => {
    e.stopPropagation();
    setIsMovingFiler(e.target.value + "");
  };

  const handleApplyFilterChanges = () => {
    if (currentEventTypeFilter !== null) {
      const filtersToUpdate = {
        ...currentEventTypeFilterOptions[currentEventTypeFilter],
      };

      filtersToUpdate.markerColor = currentSelectedShipColor;
      filtersToUpdate.country = countryFilter;
      filtersToUpdate.length = shipLengthFilter;
      filtersToUpdate.width = shipWidthFilter;
      filtersToUpdate.noImoShips = noImoShips;
      filtersToUpdate.sanctionedShips = showOnlySanctionedShip;
      filtersToUpdate.isMoving = isMovingFilter;
      filtersToUpdate.heading = headingFilter;
      filtersToUpdate.type = shipTypeFilters;
      filtersToUpdate.shipSubType = shipSubTypeFilters;

      const updatedProviderFilters = { ...filtersToUpdate.providerFilters };
      const plantscope = providerData.find(
        (source) => source.label === "PlanetScope",
      );
      if (plantscope) {
        updatedProviderFilters.planetscope = plantscope.checked;
      }
      const sentinel1 = providerData.find(
        (source) => source.label === "Sentinel-1",
      );
      if (sentinel1) {
        updatedProviderFilters.sentinel1 = sentinel1.checked;
      }
      const sentinel2 = providerData.find(
        (source) => source.label === "Sentinel-2",
      );
      if (sentinel2) {
        updatedProviderFilters.sentinel2 = sentinel2.checked;
      }
      filtersToUpdate.providerFilters = updatedProviderFilters;

      if (currentEventTypeFilter === "spoofing" && filtersToUpdate.subType) {
        const subTypes = { ...filtersToUpdate.subType };
        subTypes["ais-spoofing"] = aisSpoofingIsEnabled;
        subTypes["footprint-spoofing"] = footprintSpoofingIsEnabled;
        subTypes["optical-spoofing"] = opticalSpoofingIsEnabled;
        filtersToUpdate.subType = subTypes;
      }
      const updatedEventTypeFilterOptions = {
        ...currentEventTypeFilterOptions,
        [currentEventTypeFilter]: filtersToUpdate,
      };
      handleUpdateShipFilters(updatedEventTypeFilterOptions);
      setCurrentEventTypeFilter(null);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={styles.shipFiltersMain}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={styles.shipFiltersHead}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span
          onClick={(e) => {
            e.stopPropagation();
            setCurrentEventTypeFilter(null);
          }}
        >
          <ChevronLeft /> Go back
        </span>
        <Close
          className={styles.icon}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentEventTypeFilter(null);
          }}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        className={styles.shipFiltersWrapper}
      >
        {currentEventTypeFilter !== "similar" && (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            className={styles.shipFiltersDetail}
          >
            <span className={styles.shipFiltersLabel}>Marker Color</span>

            <Box
              display="flex"
              alignItems="center"
              className={styles.colorPickerMain}
              gap={1}
            >
              {ShipSvgs({
                color: currentSelectedShipColor,
                stroke: "#201f1f",
                type: "shipSVG",
              })}
              <HuePicker
                color={currentSelectedShipColor}
                onChange={handleColorUpdate}
                className={styles.colorPicker}
              />
            </Box>
          </Box>
        )}
        {currentEventTypeFilter !== null &&
          SHIP_FILTER_PROVIDER.includes(currentEventTypeFilter) && (
            <>
              <SelectCheckbox
                id="SHIP_DATA_SOURCE"
                options={shipDataSourceStatus}
                title="Show ships from below data source(s):"
                value={shipDataSourceList}
                onChange={handleChangeShipSource}
                label="Ship Data Sources"
                unavailableOptions={
                  currentEventTypeFilter === "dark" ? ["SAR"] : []
                }
              />
              <SelectCheckbox
                id="PROVIDER-LIST"
                options={providerData}
                title="Show ships from below service source(s):"
                value={providerList}
                onChange={handleChangeShipDataProvider}
                label="Ship Data Sources"
                unavailableOptions={
                  currentEventTypeFilter === "dark" ? ["Sentinel-1"] : []
                }
              />
            </>
          )}
        {currentEventTypeFilter !== "unattributed" &&
          currentEventTypeFilter !== "similar" && (
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              className={styles.shipFiltersDetail}
            >
              <span className={styles.shipFiltersLabel}>
                Show ships matching below flag only:
              </span>

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={COUNTRY_CODES}
                value={countryFilter}
                onChange={handleCountryUpdate}
                autoHighlight
                classes={{
                  popper: styles.shipFiltersListWrap,
                  paper: styles.shipFiltersListHead,
                  listbox: styles.shipFiltersListBox,
                }}
                className={styles.countrySelectMain}
                renderOption={(props, { code, label }) => {
                  return (
                    <Box
                      component="li"
                      display="flex"
                      alignItems="center"
                      gap={1}
                      {...props}
                      className={`${styles.shipFiltersList} ${
                        code.toLowerCase() ===
                        currentFilter.country?.code?.toLowerCase()
                          ? styles.active
                          : ""
                      }`}
                    >
                      <img
                        src={`https://flagcdn.com/48x36/${code.toLowerCase()}.png`}
                        alt=""
                        className={styles.listImage}
                      />
                      <span className={`${styles.listLabel}`}>{label} </span>
                    </Box>
                  );
                }}
                renderInput={(params) => {
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      className={styles.countrySelect}
                      ref={params.InputProps.ref}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {currentFilter.country && (
                          <img
                            className={styles.countrySelectImage}
                            src={`https://flagcdn.com/48x36/${currentFilter.country.code.toLowerCase()}.png`}
                            alt=""
                          />
                        )}
                        <input
                          type="text"
                          {...params.inputProps}
                          id="datamanagerSearchInput"
                          placeholder="Search By Flag"
                          className={styles.countryInput}
                        />
                      </div>
                      {countryFilter.code !== "" ? (
                        <CloseIcon
                          onClick={clearCountryCodeFilter}
                          fontSize="small"
                          className={styles.clearInputIcon}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  );
                }}
              />
            </Box>
          )}
        <ShipTypeFilters
          currentFilter={currentFilter}
          currentEventTypeFilter={currentEventTypeFilter}
        />
        {currentEventTypeFilter === "unattributed" && (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            className={styles.shipFiltersDetail}
          >
            <span className={styles.shipFiltersLabel}>Is Moving:</span>
            <SelectInput
              id="shipType"
              name="ship_type"
              value={isMovingFilter}
              onChange={handleIsMovingChange}
              className={styles.editMainSelect}
              optionsArray={SHIP_MOVING_OPTIONS}
              inputProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: "#26262c",
                      border: "1px solid #FFFFFF40",
                      boxShadow: "0px 4px 4px 0px #00000040",
                      color: "#fff",
                      "& .MuiMenuItem-root": {
                        padding: "6px 12px",
                        fontFamily: '"Inter", sans-serif',
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "17px",
                        gap: "6px",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#00a3e34d",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#26262c",
                          "&:hover": {
                            backgroundColor: "#00a3e34d",
                          },
                        },
                      },
                    },
                  },
                },
              }}
            />
          </Box>
        )}
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fit, minmax(130px, 1fr))"
          gap={2}
          className={styles.shipFiltersDetail}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            className={styles.sliderWrapper}
          >
            <span className={styles.shipFiltersLabel}>Vessel Length</span>
            <Slider
              max={500}
              getAriaLabel={() => "Vessel Length"}
              value={shipLengthFilter}
              onChange={handleLengthFilterUpdate}
              valueLabelDisplay="auto"
              className={styles.slider}
              classes={{
                rail: styles.sliderRail,
                track: styles.sliderTrack,
                thumb: styles.sliderThumb,
              }}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            className={styles.sliderWrapper}
          >
            <span className={styles.shipFiltersLabel}>Vessel Width</span>
            <Slider
              max={100}
              getAriaLabel={() => "Vessel Width"}
              value={shipWidthFilter}
              onChange={handleWidthFilterUpdate}
              valueLabelDisplay="auto"
              className={styles.slider}
              classes={{
                rail: styles.sliderRail,
                track: styles.sliderTrack,
                thumb: styles.sliderThumb,
              }}
            />
          </Box>
          {currentEventTypeFilter !== "similar" &&
            currentEventTypeFilter !== "spoofing" && (
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                className={styles.sliderWrapper}
              >
                <span className={styles.shipFiltersLabel}>Heading</span>
                <Slider
                  min={0}
                  max={360}
                  getAriaLabel={() => "Heading"}
                  value={headingFilter}
                  onChange={handleHeadingFilterChange}
                  valueLabelDisplay="auto"
                  className={styles.slider}
                  classes={{
                    rail: styles.sliderRail,
                    track: styles.sliderTrack,
                    thumb: styles.sliderThumb,
                  }}
                />
              </Box>
            )}
        </Box>
        {currentEventTypeFilter !== "unattributed" &&
          currentEventTypeFilter !== "similar" && (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              className={styles.shipFiltersDetail}
            >
              <Checkbox
                size="medium"
                checked={noImoShips}
                onChange={handleNoIMOShipChange}
                className={styles.checkboxMain}
                classes={{ checked: styles.checkboxChecked }}
              />
              <span className={styles.shipFiltersLabel}>
                Show ships without IMO
              </span>
            </Box>
          )}
        {currentEventTypeFilter === "spoofing" && (
          <>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              className={styles.shipFiltersDetail}
            >
              <Checkbox
                size="medium"
                checked={footprintSpoofingIsEnabled}
                onChange={handleFootprintSpoofingChange}
                className={styles.checkboxMain}
                classes={{ checked: styles.checkboxChecked }}
              />
              <span className={styles.shipFiltersLabel}>
                Show ships with Footprint only
              </span>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              className={`${styles.shipFiltersDetail} ${styles.shipFilterDisable}`}
            >
              <Checkbox
                size="medium"
                // checked={opticalSpoofingIsEnabled}
                onChange={handleOpticalSpoofingChange}
                className={styles.checkboxMain}
                classes={{ checked: styles.checkboxChecked }}
                disabled={true}
              />
              <span className={styles.shipFiltersLabel}>
                Show ships with Optical only
              </span>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              className={styles.shipFiltersDetail}
            >
              <Checkbox
                size="medium"
                checked={aisSpoofingIsEnabled}
                onChange={handleAisSpoofingChange}
                className={styles.checkboxMain}
                classes={{ checked: styles.checkboxChecked }}
              />
              <span className={styles.shipFiltersLabel}>
                Show ships with AIS only
              </span>
            </Box>
          </>
        )}
        {currentEventTypeFilter !== "spoofing" &&
          currentEventTypeFilter !== "unattributed" &&
          currentEventTypeFilter !== "similar" && (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              className={styles.shipFiltersDetail}
            >
              <Checkbox
                size="medium"
                checked={showOnlySanctionedShip}
                onChange={handleShowOnlySanctionedShipChange}
                className={styles.checkboxMain}
                classes={{ checked: styles.checkboxChecked }}
              />
              <span className={styles.shipFiltersLabel}>
                Show only sanctioned ships
              </span>
            </Box>
          )}
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2,1fr)"
        gap={2}
        className={styles.filterButton}
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setCurrentEventTypeFilter(null);
          }}
          className={styles.shipFiltersButton}
        >
          Cancel
        </Button>
        <Button
          className={styles.shipFiltersButton}
          onClick={handleApplyFilterChanges}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default ShipFilters;
