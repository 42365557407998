import { configureStore } from "@reduxjs/toolkit";
import { headerReducer } from "../layout/Header/Header.store";
import { theiaReducer } from "../pages/Theia/Theia.store";
import { homePanelReducer } from "../layout/HomePanel/HomePanel.store";
import { commonReducer } from "../common/Common.store";
import { sharedReducer } from "../pages/Shared/Shared.store";
import { notificationReducer } from "../layout/Header/Notification/Notification.store";
import { globalStateReducer } from "./globalState.store";
import { mainMapReducer } from "../pages/Theia/MainMap.store";
import { polygonShipAnalysisReducer } from "./../pages/Theia/PolygonShipAnalysis/PolygonShipAnalysis.store";
import { futurePathPredictionReducer } from "../pages/Theia/ShipTools/FuturePathPrediction/FuturePathPrediction.store";

export const store = configureStore({
  reducer: {
    header: headerReducer,
    theia: theiaReducer,
    homePanel: homePanelReducer,
    common: commonReducer,
    shared: sharedReducer,
    notification: notificationReducer,
    globalTheiaState: globalStateReducer,
    mainMap: mainMapReducer,
    polygonShipAnalysis: polygonShipAnalysisReducer,
    futurePathPrediction: futurePathPredictionReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
