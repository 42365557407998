import Map from "react-map-gl";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import RenderShips from "./RenderShips";
import { setChangeSaved } from "../Theia.store";
import MapLayerPopover from "./MapLayerPopover";
import DrawTools from "./DrawTools";
import CustomMarker from "../MapComponents/DropPIn/CustomMarker/CustomMarker";
import UserHoveredCoordinates from "../MapComponents/HoverCoordinates/UserHoveredCoordinates";
import { EventHoverData, PathHoverData } from "./types";
import useCopyCoordinates from "./hooks/useCopyCoordinates";
import useLayers from "../../../context/useLayers";
import useGlobalFilters from "../../../context/useGlobalFilter";
import useCurrentMapState from "../../../context/useCurrentMapState";
import WebcamModal from "../MapComponents/OSWebcams/WebcamModal";

const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const MapboxRender = ({
  zoomLevel,
  setZoomLevel,
  setMapLatLng,
  mapViewState,
  selectedTabId,
  deckGlLayers,
  mapRef,
  setMapSnapshot,
  setDeckCanvas,
  mapLatLng,
  detections,
  calculateShipCounts,
  currentEventTypeFilterOptions,
  droppedPins,
  setDroppedPins,
  shipPaths,
  mapStyle,
  filteredDetection,
}: any) => {
  const { webcamModalPosition, selectedWebcam } = useCurrentMapState();
  const dispatch = useDispatch();
  const { viewingLayers } = useLayers();
  const { setShowCursor } = useGlobalFilters();
  const handleShipCountCalculation = (): void => {
    if (mapRef.current !== null && detections !== null) {
      const currentMapBounds = mapRef.current.getMap().getBounds();
      const filterShipsByCoordinates = (ship: any) => {
        if (
          ship.geometry.coordinates[1] < currentMapBounds._ne.lat &&
          ship.geometry.coordinates[1] > currentMapBounds._sw.lat &&
          ship.geometry.coordinates[0] < currentMapBounds._ne.lng &&
          ship.geometry.coordinates[0] > currentMapBounds._sw.lng
        ) {
          return ship;
        }
      };
      calculateShipCounts(filterShipsByCoordinates);
    }
  };
  const [currentUserHoverCoordinates, setCurrentUserHoverCoordinates] =
    useState<[number, number] | null>([0, 0]);

  const [clickedCoordinates, setClickedCoordinates] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });
  const [selectedMapObject, setSelectedMapObject] = useState<any | null>(null);
  const [pathHoverData, setPathHoverData] = useState<PathHoverData | null>(
    null,
  );
  const [eventHoverData, setEventHoverData] = useState<EventHoverData | null>(
    null,
  );

  useEffect(() => {
    setSelectedMapObject(null);
    setClickedCoordinates({
      x: 0,
      y: 0,
    });
  }, [zoomLevel, mapLatLng]);

  useEffect(() => {
    handleShipCountCalculation();
  }, [
    mapRef.current,
    detections,
    currentEventTypeFilterOptions,
    filteredDetection,
  ]);

  const removeMarker = (i: number) => {
    const oldPins = [...droppedPins];
    oldPins.splice(i, 1);
    setDroppedPins(oldPins);
  };

  const pins = useMemo(
    () =>
      droppedPins.map((data: any, index: number) => (
        <CustomMarker
          key={`marker-${index}`}
          onClose={() => removeMarker(index)}
          {...data}
        />
      )),
    [droppedPins],
  );

  useCopyCoordinates(currentUserHoverCoordinates);

  return (
    <Map
      ref={mapRef}
      initialViewState={mapViewState}
      mapStyle={mapStyle}
      mapboxAccessToken={TOKEN}
      onZoom={(e) => {
        setZoomLevel(e.viewState.zoom);
        dispatch(setChangeSaved(false));
        setMapLatLng({
          lng: e.viewState.longitude,
          lat: e.viewState.latitude,
        });
        handleShipCountCalculation();
        setPathHoverData(null);
        setEventHoverData(null);
      }}
      onRender={() => {
        setMapSnapshot(mapRef?.current?.getCanvas()?.toDataURL("image/png"));
      }}
      minZoom={2}
      style={{ width: "100%", height: "100%" }}
      projection={"mercator" as any}
      onDrag={(e) => {
        dispatch(setChangeSaved(false));
        setMapLatLng({
          lng: e.viewState.longitude,
          lat: e.viewState.latitude,
        });
        handleShipCountCalculation();
        setPathHoverData(null);
        setEventHoverData(null);
      }}
      key={
        mapViewState.latitude +
        mapViewState.longitude +
        mapViewState.zoom +
        selectedTabId
      }
    >
      {/* <DeckGLOverlay layers={viewingLayers} getTooltip={getTooltip} /> */}
      {currentUserHoverCoordinates !== null ? (
        <UserHoveredCoordinates
          currentUserHoverCoordinates={currentUserHoverCoordinates}
        />
      ) : (
        <></>
      )}
      <MapLayerPopover
        selectedMapObject={selectedMapObject}
        clickedCoordinates={clickedCoordinates}
      />
      {selectedWebcam ? (
        <WebcamModal
          clickedCoordinates={{
            x: webcamModalPosition.x,
            y: webcamModalPosition.y,
          }}
          selectedWebcam={selectedWebcam}
        />
      ) : (
        <></>
      )}
      <RenderShips
        setDeckCanvas={setDeckCanvas}
        deckGlLayers={deckGlLayers}
        setShowCursor={setShowCursor}
        setClickedCoordinates={setClickedCoordinates}
        setSelectedMapObject={setSelectedMapObject}
        shipPaths={shipPaths}
        setCurrentUserHoverCoordinates={setCurrentUserHoverCoordinates}
        eventHoverData={eventHoverData}
        setEventHoverData={setEventHoverData}
        pathHoverData={pathHoverData}
        setPathHoverData={setPathHoverData}
      />
      {pins}
      <DrawTools />
    </Map>
  );
};

export default MapboxRender;
