// Import functions from React
import { getAuth } from "firebase/auth";
import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { api } from "../services/api";
import { useSnackbar } from "./SnackbarContext";
import { useViewAlerts } from "./ViewAlertsContext";
import { triggersList } from "../pages/Theia/SidebarComponents/AlertsComponents/AlertsBuilder/Modals/AlertTriggers/ShipTriggers";
import { GLOBAL_AOI } from "../utils/utils"


// Create a default state for the conditions object

type shipObject = {
  name: string;
  type: string;
  imo: string;
  mmsi: string;
}[]
const shipDefaultState: shipObject = [];

const alertCoordinatesState: any = {};

// Create a context for the CreateAlertContext
const CreateAlertContext = createContext({
  activeModal: "Ships",
  handleModalActivate: (modal: string) => { },
  handleAddShip: (shipDetail: {}) => { },
  handleAddTrigger: (trig: any) => { },
  ships: shipDefaultState,
  trigger: "",
  triggerDisplay: "",
  alertName: "",
  handleAddAlertName: (name: string) => { },
  emailAddress: "",
  handleEmailAddress: (email: string) => { },
  alertDate: {
    startDate: new Date(),
    endDate: new Date()
  },
  setAlertDate: (date: any) => { },
  handleCreateAlert: () => { },
  shipsFilter: true,
  setShipsFilter: (filter: boolean) => { },
  createAlertInProgress: false,
  alertCoordinates: alertCoordinatesState,
  setCoordinates: (coordinates: any) => { },
  updateAlert: (alert: any) => { },
  isUpdate: false,
  setIsUpdate: (update: boolean) => { },
})


export const CreateAlertsProvider = ({ children }: { children: ReactNode }) => {

  const { setNewSnackBar } = useSnackbar();
  const { tabValue, callAlerts } = useViewAlerts();
  const auth = getAuth();

  const [activeModal, setActiveModal] = useState<string>("");

  const [ships, setShips] = useState<any>([]);

  const [trigger, setTrigger] = useState<string>("");
  const [triggerDisplay, setTriggerDisplay] = useState<string>("");

  const [alertName, setAlertName] = useState<string>("");

  const [alertDate, setAlertDate] = useState<any>({
    startDate: new Date(),
    endDate: new Date()
  });

  const [emailAddress, setEmailAddress] = useState<string>("");

  const [shipsFilter, setShipsFilter] = useState<boolean>(true);

  const [createAlertInProgress, setCreateAlertInProgress] = useState<boolean>(false);

  const [alertCoordinates, setCoordinates] = useState<any>({});

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [alertId, setAlertId] = useState<string>("");

  useEffect(() => {
    if (tabValue === 0) {
      resetState();
    }

  }, [tabValue])

  const resetState = () => {
    setShips([]);
    setActiveModal("");
    setTrigger("");
    setTriggerDisplay("");
    setAlertName("");
    setAlertDate({
      startDate: new Date(),
      endDate: new Date()
    });
    setEmailAddress("");
    setShipsFilter(true);
    setCreateAlertInProgress(false);
    setCoordinates({});
    setIsUpdate(false);
    setAlertId("");
  }

  // Function to update alert name

  const handleAddAlertName = (name: string) => {
    setAlertName(() => name);
  }

  // Function to update email address
  const handleEmailAddress = (email: string) => {
    setEmailAddress(() => email);
  }

  // Function to handle the activation of the modal
  const handleModalActivate = (modal: string) => {
    setActiveModal(() => modal);
  }

  const handleAddShip = (shipDetail: {}) => {
    setShips(() => [shipDetail][0])
  }

  const handleAddTrigger = (trig: any) => {
    const triggerEnum = trig.enum;

    if (triggerEnum) {
      setTrigger(triggerEnum);
      setTriggerDisplay(trig.label);
    }
  }

  const handleCreateAlert = async () => {

    const globalCoordinates = GLOBAL_AOI

    const alertDataCreate = {
      "customer_alert_name": alertName,
      "user": auth?.currentUser?.email || null,
      "destination": emailAddress,
      "aoi": [
        {
          "type": "Polygon",
          "coordinates": globalCoordinates
        }
      ],
      "aoi_name": ["Global"],
      "alert_name": [trigger],
      "ship_type": null,
      "start_time": alertDate.startDate,
      "end_time": alertDate.endDate,
      "monitoring_level": "vessel",
      "synmax_ship_id": ships[0].synmax_ship_id,
    }

    const alertDataUpdate = {
      "alert_id": alertId,
      "customer_alert_name": alertName,
      "user": auth?.currentUser?.email || null,
      "destination": emailAddress,
      "aoi": {
        type: "Polygon",
        coordinates: globalCoordinates
      },
      "ship_type": null,
      "alert_name": trigger,
      "start_time": alertDate.startDate,
      "end_time": alertDate.endDate,
      "monitoring_level": "vessel",
      "synmax_ship_id": ships[0].synmax_ship_id,
    }

    try {
      setCreateAlertInProgress(true);
      if (isUpdate) {
        await api.put(`/alerts/update`, alertDataUpdate)
        setNewSnackBar({ message: `Alert updated with alert id ${alertId}`, severity: "success" });
        callAlerts()
      } else {
        const response: any = await api.post(`/alerts/create`, alertDataCreate);
        setNewSnackBar({ message: `New alert created with alert id ${response?.data.alert_id}`, severity: "success" });
        callAlerts()
      }

      resetState();
    } catch (error) {
      setNewSnackBar({ message: `${error}`, severity: "error" });
    }

    setCreateAlertInProgress(false);
  }

  const updateAlert = (alert: any) => {
    const ship = [{
      synmax_ship_id: alert.synmax_ship_id,
      name: alert.name,
    }]
    const displayName = triggersList.filter(trigger => trigger.enum === alert.alert_name)[0]?.label;
    setAlertId(alert.alert_id);
    setShips(ship);
    setTrigger(alert.alert_name);
    setTriggerDisplay(displayName);
    setAlertName(alert.customer_alert_name);
    setAlertDate({
      startDate: alert.start_date,
      endDate: alert.end_date
    });
    setEmailAddress(alert.destination);
  }



  // Return the CreateAlertContext.Provider with the value of the context
  return (
    <CreateAlertContext.Provider
      value={{
        activeModal,
        handleModalActivate,
        ships,
        handleAddShip,
        trigger,
        handleAddTrigger,
        triggerDisplay,
        alertName,
        handleAddAlertName,
        emailAddress,
        handleEmailAddress,
        alertDate,
        setAlertDate,
        handleCreateAlert,
        shipsFilter,
        setShipsFilter,
        createAlertInProgress,
        alertCoordinates,
        setCoordinates,
        updateAlert,
        isUpdate,
        setIsUpdate,
      }}
    >
      {children}
    </CreateAlertContext.Provider>
  )

}


// Function to use the CreateAlertContext
export const useCreateAlerts = () => {

  const context = useContext(CreateAlertContext);
  if (context === undefined) {
    throw new Error("useCreateAlerts must be used within a CreateAlertsProvider");
  }
  return useContext(CreateAlertContext);
}